// UploadComponent.js

import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { doc, updateDoc, arrayUnion, arrayRemove } from '@firebase/firestore';
import { storage, db } from '../../firebase';
import { motion, AnimatePresence } from 'framer-motion';

import plusIcon from '../../icons/plusIcon.png';
import deleteIcon from '../../icons/deleteIcon.png';
import loadIcon from '../../icons/scoutIcon.png';
import infoIcon from '../../icons/infoIcon.png';
import ImageTags from './ImageTags';

// import our new color extraction function
import { extractColors } from '../../components/ColorExtractor';

import './AddProduct.css';

const UploadComponent = ({
  productID,
  setImageUrl,
  imageUrl,
  setThumbnailUrl,
  setImagesChanged,
  setUploadImages,
  uploadImages,
  setPercentage,
  percentage,
  setShowProgressBar,
  completedUploads,
  setCompletedUploads,
  setProgressCompleted,
  setFetchedImageUrl,
  setFetchedImageThumbnail,
  handleFieldChange,
  initialImages = [],
  isEditing = false,
  setTags,
  existingTags = []
}) => {
  const [tags, setTagsState] = useState([]);
  const [error, setError] = useState('');
  const [isTagGenerating, setIsTagGenerating] = useState(false);
  const [showUploadInfo, setShowUploadInfo] = useState(false);

  useEffect(() => {
    if (percentage === 100) {
      setPercentage(null);
      setShowProgressBar(false);
      setProgressCompleted(true);
    } else if (percentage !== null) {
      setShowProgressBar(true);
    }
  }, [percentage, setPercentage, setShowProgressBar, setProgressCompleted]);

  useEffect(() => {
    // If editing and existingTags are available, set them
    if (isEditing && existingTags.length > 0) {
      setTagsState(existingTags);
      setTags(existingTags);
    }
  }, [isEditing, existingTags, setTags]);

  useEffect(() => {
    if (isEditing && initialImages.length > 0 && uploadImages.length === 0) {
      setUploadImages(
        initialImages.map((img) => ({
          ...img,
          completed: img.completed !== undefined ? img.completed : true,
          fromFirebase: img.fromFirebase || false,
        }))
      );
    }
  }, [isEditing, initialImages, uploadImages.length, setUploadImages]);

  useEffect(() => {
    // Cleanup function to revoke object URLs when the component unmounts
    return () => {
      uploadImages.forEach((img) => {
        if (img.preview && !img.fromFirebase) {
          URL.revokeObjectURL(img.preview);
        }
      });
    };
  }, [uploadImages]);

  const handleImageUpload = async (e) => {
    setImagesChanged(true);
    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    const remainingSlots = 5 - uploadImages.length;
    if (files.length > remainingSlots) {
      setError(`You can only upload ${remainingSlots} more image(s).`);
      e.target.value = '';
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.size > 5 * 1024 * 1024) {
        setError(
          'One or more files exceed the 5 MB size limit. Please select smaller files.'
        );
        continue;
      }

      const fileName = file.name;
      const isAlreadyUploaded = uploadImages.some(
        (upload) => upload.file && upload.file.name === fileName
      );

      if (isAlreadyUploaded) {
        setError(`The image "${fileName}" has already been uploaded.`);
        continue;
      }

      const name = file.name + uuidv4();
      const storageRef = ref(storage, `product images/${name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Use FileReader to generate the preview
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const previewURL = reader.result;
        setUploadImages((prev) => [
          ...prev,
          { file, progress: 0, preview: previewURL, completed: false, fromFirebase: false },
        ]);
      };

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadImages((prev) =>
            prev.map((img) =>
              img.file === file
                ? { ...img, progress, completed: progress === 100 }
                : img
            )
          );
          setPercentage(progress);
        },
        (error) => {
          console.error('Error uploading file: ', error);
          setError('Error uploading one or more files. Please try again.');
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            const resizedThumbnail = resizeThumbnailImageUrl(downloadURL);

            const dbRef = doc(db, 'products', productID);
            // Update Firestore images and thumbnails
            await updateDoc(dbRef, {
              images: arrayUnion(downloadURL),
              imageThumbnails: arrayUnion(resizedThumbnail),
            });
            console.log('Image URL added to Firestore');

            // Set the main image URL for the product if not already set
            if (!imageUrl) {
              setImageUrl(downloadURL);
              setThumbnailUrl(resizedThumbnail);
            }

            setUploadImages((prev) =>
              prev.map((img) =>
                img.file === file ? { ...img, preview: downloadURL, completed: true } : img
              )
            );
            setCompletedUploads((prev) => prev + 1);
            handleFieldChange();
            setError('');

            // ====> Perform the color extraction and store in Firestore <====
            try {
              const colorPalette = await extractColors(downloadURL); 
              // This will overwrite if there's already a backgroundColors field,
              // but you can add extra logic if you only want to set it once, etc.
              await updateDoc(dbRef, {
                backgroundColors: colorPalette,
              });
              console.log(colorPalette)
              console.log('backgroundColors field has been set in Firestore');
            } catch (colorError) {
              console.error('Error extracting colors:', colorError);
            }

            // If this is the first image of the product, auto-generate tags
            const isFirstImage = uploadImages.length === 0 && i === 0;
            if (isFirstImage) {
              try {
                setIsTagGenerating(true);
                const response = await fetch('https://generateimagetags-a6vcfarowq-uc.a.run.app', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ imageUrl: downloadURL }),
                });

                if (!response.ok) {
                  throw new Error(`Server responded with status ${response.status}`);
                }

                const data = await response.json();
                if (data.tags && Array.isArray(data.tags)) {
                  console.log('Generated Tags:', data.tags);
                  const tagStrings = data.tags.map((tagObj) => tagObj.tag);
                  setTagsState(tagStrings);
                  setTags(tagStrings);
                } else {
                  console.error('Invalid response format:', data);
                }
              } catch (error) {
                console.error('Error generating tags:', error.message);
              } finally {
                setIsTagGenerating(false);
              }
            }
          } catch (error) {
            console.error('Error during post-upload processing:', error);
            setError('Error processing one or more uploaded images. Please try again.');
          }
        }
      );
    }

    // Reset the input value to allow re-uploading the same file if needed
    e.target.value = '';
  };

  const removeExistingResolution = (url) => {
    return url.replace(/(_1000x1000|_150x150)/g, '');
  };

  const resizeThumbnailImageUrl = (url) => {
    const cleanedUrl = removeExistingResolution(url);
    return resizeImageUrl(cleanedUrl, '_150x150');
  };

  const resizeImageUrl = (url, resolution) => {
    const queryParamIndex = url.lastIndexOf('?');
    return queryParamIndex === -1
      ? url + resolution
      : url.substring(0, queryParamIndex) +
          resolution +
          url.substring(queryParamIndex);
  };

  const handleDelete = async (index = null) => {
    setImagesChanged(true);
    const imageToDelete = uploadImages[index];
    if (!imageToDelete || !imageToDelete.preview) return;

    const newUploadImages = uploadImages.filter((_, i) => i !== index);
    setUploadImages(newUploadImages);

    if (imageToDelete.completed) {
      setCompletedUploads((prev) => prev - 1);
    }

    const dbRef = doc(db, 'products', productID);
    const updatedAttributes = {
      images: arrayRemove(imageToDelete.preview),
      imageThumbnails: arrayRemove(resizeThumbnailImageUrl(imageToDelete.preview)),
    };

    try {
      await updateDoc(dbRef, updatedAttributes);
      console.log(`${imageToDelete.preview} removed from Firestore document ${productID}`);
    } catch (error) {
      console.error('Error updating Firestore document:', error);
      setError('Error removing image from database. Please try again.');
    }

    if (imageToDelete.fromFirebase) {
      try {
        let fileName = imageToDelete.preview
          .slice(89, imageToDelete.preview.lastIndexOf('?'))
          .replace(/ /g, '%20');
        fileName = decodeURIComponent(fileName);

        const baseFileName = fileName.replace('_1000x1000', '');
        const deleteRefOriginal = ref(storage, `product images/${baseFileName}`);
        const deleteRefLarge = ref(storage, `product images/${baseFileName}_1000x1000`);
        const deleteRefThumbnail = ref(storage, `product images/${baseFileName}_150x150`);

        await Promise.all([
          deleteObject(deleteRefOriginal),
          deleteObject(deleteRefLarge),
          deleteObject(deleteRefThumbnail),
        ]);

        console.log('Images successfully deleted from Firebase');
        handleFieldChange();
      } catch (error) {
        console.error('Error deleting images from Firebase:', error);
        setError('Error deleting image from storage. Please try again.');
      }
    }

    if (index === 0) {
      setImageUrl(null);
      setThumbnailUrl(null);
      setFetchedImageUrl('');
      setFetchedImageThumbnail('');
      setTags([]);
      setTagsState([]);
    } else if (newUploadImages.length > 0) {
      setImageUrl(newUploadImages[0].preview);
      setThumbnailUrl(resizeThumbnailImageUrl(newUploadImages[0].preview));
      setFetchedImageUrl(newUploadImages[0].preview);
      setFetchedImageThumbnail(resizeThumbnailImageUrl(newUploadImages[0].preview));
    } else {
      setImageUrl(null);
      setThumbnailUrl(null);
      setFetchedImageUrl('');
      setFetchedImageThumbnail('');
    }

    setError('');
  };

  const maxImagesReached = uploadImages.length >= 5;

  return (
    <motion.div
      className="uploadProductMediaDiv"
      layout
      initial={{ height: 'auto' }}
      animate={{ height: 'auto' }}
      transition={{ duration: 0.5 }}
    >
      <div className="uploadProductImagesHeader">upload product images</div>
      <div className="uploadProductMediaContainer">
        {uploadImages &&
          uploadImages.map((upload, index) => (
            <div key={index} className="imageUploadPreviewDiv">
              <motion.img
                src={upload.preview}
                className="imageUploadPreview"
                alt={`Uploading ${index + 1}`}
              />
              {index === 0 && isTagGenerating && (
                <motion.div
                  className="scanningOverlay"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [0.4, 0.7, 0.4] }}
                  transition={{ duration: 1, repeat: Infinity, repeatType: 'loop' }}
                />
              )}
              {upload.completed ? (
                <div id="imageUploadDeleteBtn" onClick={() => handleDelete(index)}>
                  <img
                    src={deleteIcon}
                    alt="Delete Uploaded"
                    className="deleteIcon"
                    id="deleteIcon"
                  />
                </div>
              ) : (
                <progress className="progressBar" value={upload.progress} max="100"></progress>
              )}
            </div>
          ))}

        {!maxImagesReached && uploadImages.length < 5 && (
          <label
            htmlFor="inputField"
            className="addProductImagePlusBtn"
            style={{ margin: uploadImages.length === 0 ? '0px' : '10px 0 0 15px' }}
          >
            <img className="addProductImagePlusBtnIcon" src={plusIcon} alt="Upload More" />
          </label>
        )}
      </div>

      <input
        style={{ display: 'none' }}
        type="file"
        id="inputField"
        onChange={handleImageUpload}
        accept="image/*"
        multiple
      />

      {/* Info icon in the top right corner */}
      <img
        src={infoIcon}
        className="bundlingInfoIcon"
        style={{ marginLeft: '8px', cursor: 'pointer' }}
        onClick={() => setShowUploadInfo(!showUploadInfo)}
        alt="Info"
      />
      {showUploadInfo && (
        <div className="bundlingInfoDiv" style={{ marginTop: '10px' }}>
          <p className="bundlingInfoText">upload up to 5 images to showcase your product</p>
          <p className="bundlingInfoText">each image uploaded should be max 5MB</p>
          <div className="bundlingInfoBtn" onClick={() => setShowUploadInfo(false)}>
            got it
          </div>
        </div>
      )}

      {error && <div className="addProductErrorMessage">{error}</div>}

      {maxImagesReached && uploadImages.length === 5 && (
        <div className="addProductErrorMessage">only 5 product images max are allowed</div>
      )}

      <AnimatePresence>
        {tags.length > 0 && (
          <motion.div
            key="imageTags"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.5 }}
          >
            <ImageTags
              tags={tags}
              setTags={(newTags) => {
                setTagsState(newTags);
                setTags(newTags);
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>

      {isTagGenerating && (
        <div className="imageUploadLoadingDiv">
          <img src={loadIcon} className="marketLoadingIcon" alt="loading icon" />
        </div>
      )}
    </motion.div>
  );
};

export default UploadComponent;
