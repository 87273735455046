import { useState, useEffect, useRef, useMemo } from "react";
import { getAuth } from "firebase/auth";
import { doc, updateDoc, setDoc, getDoc } from '@firebase/firestore';
import { auth, db } from '../firebase';
import { motion, AnimatePresence } from "framer-motion";
import './BuyerInfoPopup.css';

import BuyerDetails from "./BuyerDetails";
import BuyerShippingDetails from "./BuyerShippingDetails";
import closeIcon from '../icons/deleteIcon.png';
import { shippingInformation } from './ShippingInformation';

const swipeVariants = {
  initial: (direction) => ({ x: direction > 0 ? 300 : -300, opacity: 0 }),
  animate: { x: 0, opacity: 1 },
  exit: (direction) => ({ x: direction > 0 ? -300 : 300, opacity: 0 }),
};

const BuyerInfoPopup = ({ cartData, onDone, onClose, totalSum, totalShippingPrice }) => {
  // State declarations (most of these remain unchanged)
  const [buyerAddress, setBuyerAddress] = useState('');
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [email, setEmail] = useState('');
  const [submitStatus, setSubmitStatus] = useState(null);
  const [isFetchingLocation, setIsFetchingLocation] = useState(false);
  const [locationError, setLocationError] = useState(null);

  const [paxiStoreCode, setPaxiStoreCode] = useState('');
  const [pudoLockerAddress, setPudoLockerAddress] = useState('');
  const [showPaxiInput, setShowPaxiInput] = useState(false);
  const [showPudoInput, setShowPudoInput] = useState(false);
  const [showRecap, setShowRecap] = useState(false);
  const [showMethodInfo, setShowMethodInfo] = useState({});
  const [missingFieldsMsg, setMissingFieldsMsg] = useState(null);

  // New state for navigation and animation
  const [currentStep, setCurrentStep] = useState(1);
  const [direction, setDirection] = useState(1);

  const authInstance = getAuth();
  const MAPBOX_SUGGESTIONS_URL = "https://mapboxgeocoding-a6vcfarowq-uc.a.run.app/get-address-suggestions";
  const MAPBOX_REVERSE_URL = "https://mapboxgeocoding-a6vcfarowq-uc.a.run.app/get-address-from-coordinates";
  const debounceTimer = useRef(null);
  const addressContainerRef = useRef();
  const popupRef = useRef(null);
  const buttonRef = useRef(null);
  const recapRef = useRef(null);

  // (The following useEffects and functions remain largely unchanged.)
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickInsidePopup = popupRef.current && popupRef.current.contains(event.target);
      const isClickOnButton = buttonRef.current && buttonRef.current.contains(event.target);
      const isClickInsideRecap = recapRef.current && recapRef.current.contains(event.target);

      if (!isClickInsidePopup && !isClickOnButton && !isClickInsideRecap) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const fetchAddressSuggestions = (query) => {
    if (debounceTimer.current) clearTimeout(debounceTimer.current);
    if (query.length < 3) {
      setAddressSuggestions([]);
      setIsSuggestionsVisible(false);
      return;
    }
    debounceTimer.current = setTimeout(() => {
      const user = authInstance.currentUser;
      if (!user) {
        setAddressSuggestions([]);
        setIsSuggestionsVisible(false);
        return;
      }
      user.getIdToken()
        .then((idToken) => {
          fetch(MAPBOX_SUGGESTIONS_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${idToken}`,
            },
            body: JSON.stringify({ query }),
          })
            .then(response => {
              if (!response.ok) throw new Error(`Status ${response.status}`);
              return response.json();
            })
            .then(data => {
              if (data.suggestions) {
                setAddressSuggestions(data.suggestions);
                setIsSuggestionsVisible(true);
              } else {
                setAddressSuggestions([]);
                setIsSuggestionsVisible(false);
              }
            })
            .catch(error => {
              console.error("Error fetching suggestions:", error);
              setAddressSuggestions([]);
              setIsSuggestionsVisible(false);
            });
        })
        .catch(error => {
          console.error("Error getting token:", error);
          setAddressSuggestions([]);
          setIsSuggestionsVisible(false);
        });
    }, 500);
  };

  const handleSuggestionClick = (suggestion) => {
    const formattedPlaceName = suggestion.place_name.replace(/,\s*South Africa$/, '');
    setBuyerAddress(formattedPlaceName);
    setAddressSuggestions([]);
    setIsSuggestionsVisible(false);
    if (addressContainerRef.current) {
      const input = addressContainerRef.current.querySelector('input');
      if (input) input.blur();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'address') {
      setBuyerAddress(value);
      fetchAddressSuggestions(value);
    } else if (name === 'firstName') {
      setFirstName(value);
    } else if (name === 'surname') {
      setSurname(value);
    } else if (name === 'cellphone') {
      setCellphone(value);
    } else if (name === 'email') {
      setEmail(value);
    } else if (name === 'paxiStoreCode') {
      setPaxiStoreCode(value);
    } else if (name === 'pudoLockerAddress') {
      setPudoLockerAddress(value);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addressContainerRef.current && !addressContainerRef.current.contains(event.target)) {
        setIsSuggestionsVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = authInstance.currentUser;
      if (currentUser) {
        try {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userSnap = await getDoc(userDocRef);
          if (userSnap.exists()) {
            const data = userSnap.data();
            setFirstName(data.firstName || '');
            setSurname(data.surname || '');
            setCellphone(data.cellphone || '');
            setBuyerAddress(data.address || '');
            setEmail(data.email || '');
            setPaxiStoreCode(data.paxiStoreCode || '');
            setPudoLockerAddress(data.pudoLockerAddress || '');
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };
    fetchUserData();
  }, [authInstance]);

  // Compute courier methods (as before)
  const uniqueCourierMethods = useMemo(() => {
    const methodMap = {};
    cartData.forEach(product => {
      const method = product.courierMethod;
      const subgroup = product.selected_subgroup;
      if (method) {
        if (!methodMap[method]) {
          methodMap[method] = new Set();
        }
        if (subgroup) {
          methodMap[method].add(subgroup);
        }
      }
    });
    return Object.keys(methodMap).map(method => ({
      courierMethod: method,
      subgroups: Array.from(methodMap[method])
    }));
  }, [cartData]);

  const sortedCourierMethods = useMemo(() => {
    if (!uniqueCourierMethods || uniqueCourierMethods.length === 0) return [];
    const aramexMethod = uniqueCourierMethods.find(item => item.courierMethod === 'Aramex');
    const otherMethods = uniqueCourierMethods.filter(item => item.courierMethod !== 'Aramex');
    return aramexMethod ? [aramexMethod, ...otherMethods] : uniqueCourierMethods;
  }, [uniqueCourierMethods]);

  // (Validation and submission functions remain essentially the same.)
  const validatePaxiStoreCode = (code) => /^P\d{4}$/.test(code);

  const getMissingFields = () => {
    const missing = [];
    if (!firstName.trim()) missing.push("First Name");
    if (!surname.trim()) missing.push("Surname");
    if (!cellphone.trim()) missing.push("Cellphone");
    if (!buyerAddress.trim()) missing.push("Physical Address");
    if (!email.trim()) {
      missing.push("Email");
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email.trim())) {
        missing.push("Valid Email");
      }
    }
    uniqueCourierMethods.forEach(({ courierMethod, subgroups }) => {
      if (courierMethod === 'Paxi') {
        if (!paxiStoreCode.trim()) {
          missing.push("Paxi Store Code");
        } else if (!validatePaxiStoreCode(paxiStoreCode.trim())) {
          missing.push("Valid Paxi Store Code (e.g. P1234)");
        }
      }
      if (courierMethod === 'Pudo') {
        const relevantSubgroups = ['Locker-to-Locker', 'Door-to-Locker'];
        const shouldRequireLocker = subgroups.some(sub => relevantSubgroups.includes(sub));
        if (shouldRequireLocker && !pudoLockerAddress.trim()) {
          missing.push("Pudo Locker Address");
        }
      }
    });
    return missing;
  };

  const canMoveNext = useMemo(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return (
      firstName.trim() !== '' &&
      surname.trim() !== '' &&
      cellphone.trim() !== '' &&
      buyerAddress.trim() !== '' &&
      email.trim() !== '' &&
      emailRegex.test(email.trim())
    );
  }, [firstName, surname, cellphone, buyerAddress, email]);
  

  const canShowSubmit = useMemo(() => {
    if (!firstName.trim() || !surname.trim() || !cellphone.trim() || !buyerAddress.trim() || !email.trim()) {
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.trim())) return false;
    for (let methodObj of uniqueCourierMethods) {
      const { courierMethod, subgroups } = methodObj;
      if (courierMethod === 'Paxi') {
        if (!paxiStoreCode.trim() || !validatePaxiStoreCode(paxiStoreCode.trim())) return false;
      }
      if (courierMethod === 'Pudo') {
        const relevantSubgroups = ['Locker-to-Locker', 'Door-to-Locker'];
        const shouldRequireLocker = subgroups.some(sub => relevantSubgroups.includes(sub));
        if (shouldRequireLocker && !pudoLockerAddress.trim()) return false;
      }
    }
    return true;
  }, [firstName, surname, cellphone, buyerAddress, email, paxiStoreCode, pudoLockerAddress, uniqueCourierMethods]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!canShowSubmit) {
      setMissingFieldsMsg(getMissingFields());
      return;
    }
    setMissingFieldsMsg(null);
    const currentUser = authInstance.currentUser;
    if (!currentUser) {
      setSubmitStatus({ success: false, message: "User not authenticated." });
      return;
    }
    const userDocRef = doc(db, "users", currentUser.uid);
    const buyerInfoData = {
      firstName: firstName.trim(),
      surname: surname.trim(),
      cellphone: cellphone.trim(),
      address: buyerAddress.trim(),
      email: email.trim(),
      userID: currentUser.uid,
      updatedAt: new Date(),
      paxiStoreCode: paxiStoreCode.trim() || null,
      pudoLockerAddress: pudoLockerAddress.trim() || null
    };
    try {
      const userSnap = await getDoc(userDocRef);
      if (userSnap.exists()) {
        await updateDoc(userDocRef, buyerInfoData);
      } else {
        await setDoc(userDocRef, buyerInfoData);
      }
      setSubmitStatus({ success: true, message: "Your information has been saved successfully." });
      if (onDone) onDone(buyerInfoData);
    } catch (error) {
      console.error("Error saving user data:", error);
      setSubmitStatus({ success: false, message: "An error occurred while saving your information. Please try again." });
    }
  };

  const handleUseCurrentAddress = () => {
    setIsFetchingLocation(true);
    setLocationError(null);
    if (!navigator.geolocation) {
      setLocationError("Geolocation is not supported by your browser.");
      setIsFetchingLocation(false);
      return;
    }
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        const user = authInstance.currentUser;
        if (!user) {
          setLocationError("User not authenticated.");
          setIsFetchingLocation(false);
          return;
        }
        try {
          const idToken = await user.getIdToken();
          const response = await fetch(MAPBOX_REVERSE_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${idToken}`,
            },
            body: JSON.stringify({ latitude, longitude }),
          });
          if (!response.ok) throw new Error(`Status ${response.status}`);
          const data = await response.json();
          if (data.address) {
            setBuyerAddress(data.address);
            setIsSuggestionsVisible(false);
          } else {
            setLocationError("Unable to retrieve your address.");
          }
        } catch (error) {
          console.error("Error fetching address:", error);
          setLocationError("An error occurred while fetching your address.");
        } finally {
          setIsFetchingLocation(false);
        }
      },
      (error) => {
        console.error("Geolocation error:", error);
        setLocationError(error.code === error.PERMISSION_DENIED
          ? "Permission denied. Unable to access your location."
          : "An error occurred while fetching your location.");
        setIsFetchingLocation(false);
      }
    );
  };

  const toggleMethodInfo = (method) => {
    setShowMethodInfo(prev => ({ ...prev, [method]: !prev[method] }));
  };

  // Navigation functions
  const handleNext = () => {
    setDirection(1);
    setCurrentStep(2);
  };

  const handleBack = () => {
    setDirection(-1);
    setCurrentStep(1);
  };

  return (
    <>
      {!showRecap && (
        <button 
          type="button" 
          className="paymentRecapBtn" 
          onClick={() => setShowRecap(true)}
          ref={buttonRef}
          aria-label="Open Payment Recap"
        >
          Payment Recap
        </button>
      )}

      <div className="buyerInfoPopupBlur">
        {showRecap && (
          <motion.div 
            className="paymentRecap"
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            exit={{ y: -50 }}
            transition={{ duration: 0.3 }}
            ref={recapRef}
          >
            <div className="paymentRecapHeader">
              <div className="paymentRecapHeaderText">payment recap</div>
              <button 
                className="closeRecapBtn" 
                onClick={() => setShowRecap(false)} 
                aria-label="Close Payment Recap"
              >
                <img src={closeIcon} alt="Close Recap" className="closeIcon" />
              </button>
            </div>
            <div className="paymentRecapContent">
              <div className="paymentRecapRow">
                <div>Products:</div><div>R{(totalSum ?? 0).toFixed(2)}</div>
              </div>
              <div className="paymentRecapRow">
                <div>Shipping &amp; Handling:</div><div>R{(totalShippingPrice ?? 0).toFixed(2)}</div>
              </div>
              <hr />
              <div className="paymentRecapTotal">
                <div>Total:</div><div>R{((totalSum ?? 0) + (totalShippingPrice ?? 0)).toFixed(2)}</div>
              </div>
            </div>
          </motion.div>
        )}
        <AnimatePresence exitBeforeEnter custom={direction}>
          <motion.div 
            key={currentStep}
            variants={swipeVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            custom={direction}
            transition={{ duration: 0.3 }}
            className="buyerInfoPopupDiv"
            ref={popupRef}
          >
            <form onSubmit={handleSubmit} className="buyerInfoForm" id="buyerInfoForm">
              {currentStep === 1 && (
                <>
                  <BuyerDetails 
                    firstName={firstName}
                    surname={surname}
                    email={email}
                    cellphone={cellphone}
                    buyerAddress={buyerAddress}
                    isSuggestionsVisible={isSuggestionsVisible}
                    addressSuggestions={addressSuggestions}
                    handleChange={handleChange}
                    handleSuggestionClick={handleSuggestionClick}
                    handleUseCurrentAddress={handleUseCurrentAddress}
                    isFetchingLocation={isFetchingLocation}
                  />
                  <button 
                    type="button" 
                    onClick={handleNext} 
                    className="buyerInfoNextButton"
                    style={{ float: 'right', backgroundColor: canMoveNext ? '' : 'rgba(0, 0, 0, 0.5)' }}
                    disabled={!canMoveNext}
                  >
                    next
                  </button>
                </>
              )}
              {currentStep === 2 && (
                <>
                  <BuyerShippingDetails 
                    cartData={cartData}
                    uniqueCourierMethods={uniqueCourierMethods}
                    sortedCourierMethods={sortedCourierMethods}
                    paxiStoreCode={paxiStoreCode}
                    pudoLockerAddress={pudoLockerAddress}
                    showPaxiInput={showPaxiInput}
                    setShowPaxiInput={setShowPaxiInput}
                    showPudoInput={showPudoInput}
                    setShowPudoInput={setShowPudoInput}
                    toggleMethodInfo={toggleMethodInfo}
                    showMethodInfo={showMethodInfo}
                    shippingInformation={shippingInformation}
                    handleChange={handleChange}
                    buyerAddress={buyerAddress}
                  />
                  <div className="navigationButtons">
                    <button type="button" onClick={handleBack} className="buyerInfoBackButton" style={{ float: 'left' }}>
                      back
                    </button>
                    <button 
                      id="buyerInfoSubmitBtn" 
                      type="submit" 
                      className="cartSubmitButton"
                      style={{ float: 'right', backgroundColor: canShowSubmit ? '' : 'grey', cursor: canShowSubmit ? 'pointer' : 'not-allowed' }}
                    >
                      submit
                    </button>
                  </div>
                </>
              )}
              {missingFieldsMsg && (
                <div className="missingFieldsMessage">
                  Please fill in the following required field{missingFieldsMsg.length > 1 ? "s" : ""}: {missingFieldsMsg.join(', ')}
                </div>
              )}
              {submitStatus && (
                <div className={`submitStatus ${submitStatus.success ? 'success' : 'error'}`}>
                  {submitStatus.message}
                </div>
              )}
              {locationError && (
                <div className="submitStatus error">
                  {locationError}
                </div>
              )}
            </form>
          </motion.div>
        </AnimatePresence>
      </div>
    </>
  );
};

export default BuyerInfoPopup;
