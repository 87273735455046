import React from 'react';
import { Link, Outlet } from "react-router-dom";

import './LearnMore.css'

import backArrow from '../icons/backArrow.png'

const LearnMore = () => {
  return (
    <div className="learnMorePage">
      <div className="learnMoreHeader">
        <div className="learnMoreHeaderSec1">
          <div className="learnMoreTopSection">
            <Link to="/home">
              <div className="learnMoreBackBtn">
                <img src={backArrow} alt="" className="learnMoreBackArrowIcon" />
              </div>
            </Link>
            <div className="learnMoreTopSectionHeader">for creators</div>
          </div>
        </div>
        <div className="learnMoreHeaderSec2">
          Selling online shouldn’t be daunting
        </div>
      </div>
      <div className="learnMoreInfoSection1">
        Launch your online store in minutes - no technical skills 
        needed & have your products listed on the <em>taizte</em> marketplace.
      </div>
    </div>
  );
};

export default LearnMore;
