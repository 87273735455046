import React, { useState, useRef, useEffect, useMemo } from 'react'; 
import { Outlet, useNavigate } from "react-router-dom"; 
import { doc, getDoc, getDocs, query, where, collection, Timestamp } from '@firebase/firestore'; 
import { getAuth, onAuthStateChanged } from "firebase/auth"; 
import { auth, db } from '../firebase'; 
import { motion, AnimatePresence } from "framer-motion"; 

import './Home.css'; 
import logoIcon from '../icons/logoIcon.png'; 
import heartIcon from '../icons/heartIcon.png'; 
import accountIcon from '../icons/accountIcon.png'; 
import blankImage from '../images/blankImage.png'; 
import notificationIcon from "../icons/notificationIcon.png"; 
import NotificationPopup from "./NotificationPopup"; 
import Login from '../login/Login'; 
import DeliveryNotifications from './DeliveryNotifications'; // Import the new component

import Explainer from './Explainer'; // Import the Explainer component

import image1 from '../images/1.png'
import image2 from '../images/2.png'
import image3 from '../images/3.png'
import image4 from '../images/4.png'
// Import icons for navigation arrows
import backArrow from '../icons/returnArrowIcon.png'; // Back arrow for Step 1
import forwardArrow from '../icons/backArrow.png'; // Forward arrow for Step 1
import backArrowSecond from '../icons/backArrow.png'; // Back arrow for Step 2

const Home = (props) => { 
  const loginPopupRef = useRef(); 
  const timeoutRef = useRef(null); // Ref to store the timeout ID
  const navigate = useNavigate()

  const [showInfo1, setShowInfo1] = useState(false); 
  // const [showHomeIcons, setShowHomeIcons] = useState(false); 
  const [showNotifications, setShowNotifications] = useState(false); 
  const [notifications, setNotifications] = useState([]); 
  const [readNotifications, setReadNotifications] = useState([]); 
  const [unreadNotifications, setUnreadNotifications] = useState([]); 
  const [popupClosed, setPopupClosed] = useState(false); 
  const [currentUser, setCurrentUser] = useState(null); 
  const [isSeller, setIsSeller] = useState(false); 

  // New states for delivery notifications product details
  const [deliveryNotificationProducts, setDeliveryNotificationProducts] = useState({}); 
  const [expandedNotifications, setExpandedNotifications] = useState({}); 
  const [selectedProduct, setSelectedProduct] = useState(null); // For product card popup

  // New state for the explainer
  const [isExplainerOpen, setIsExplainerOpen] = useState(false);
  const [explainerStep, setExplainerStep] = useState(1); // 1 or 2
  const [direction, setDirection] = useState(0); // 1 for forward, -1 for back

  // New state to track if Explainer is inside Login
  const [isExplainerInsideLoginOpen, setIsExplainerInsideLoginOpen] = useState(false);

  useEffect(() => { 
    const unsubscribe = onAuthStateChanged(auth, async (user) => { 
      // Clear any existing timeout when auth state changes
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      if (user) { 
        // setShowHomeIcons(true); 
        setCurrentUser(user); 
        const docRef = doc(db, "users", user.uid); 
        const docSnap = await getDoc(docRef); 
        if (docSnap.exists()) { 
          const userData = docSnap.data(); 
          setIsSeller(!!userData.storeID); 
        } else { 
          setIsSeller(false); 
        }

        // Set a timeout to hide the login popup after 2 seconds
        timeoutRef.current = setTimeout(() => {
          setShowInfo1(false);
          timeoutRef.current = null; // Clear the ref after timeout
        }, 2000);
      } else { 
        // setShowHomeIcons(false); 
        // setShowInfo1(true); 
        setCurrentUser(null); 
        setShowNotifications(false); 
        setIsSeller(false); 
      } 
    }); 

    // Cleanup function to unsubscribe and clear the timeout
    return () => {
      unsubscribe(); 
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => { 
    document.title = 'Home | taizte™'; 
  }, []);

  useEffect(() => { 
    if (!props.userID) return; 
    getNotifications(); 
  }, [props.userID]); 

  const getNotifications = async () => {
    try {
      const q = query(collection(db, "notifications"), where("userID", "==", props.userID));
      const querySnapshot = await getDocs(q);
      let fetchedNotifications = [];
      querySnapshot.forEach((doc) => {
        let notification = doc.data();
        notification.id = doc.id;

        // Normalize parcelImages to always be an array
        if (notification.parcelImages && !Array.isArray(notification.parcelImages)) {
          notification.parcelImages = [notification.parcelImages];
        }

        // Convert messageDateNumeric to milliseconds if it's a Firestore Timestamp
        if (notification.messageDateNumeric) {
          if (notification.messageDateNumeric instanceof Timestamp) {
            notification.messageDateNumeric = notification.messageDateNumeric.toMillis();
          } else if (typeof notification.messageDateNumeric === 'object' && notification.messageDateNumeric.seconds) {
            // Handle alternative Firestore Timestamp formats
            notification.messageDateNumeric = notification.messageDateNumeric.seconds * 1000 + notification.messageDateNumeric.nanoseconds / 1000000;
          } else if (typeof notification.messageDateNumeric !== 'number') {
            // Attempt to parse it as a number if it's a string
            const parsed = Number(notification.messageDateNumeric);
            notification.messageDateNumeric = isNaN(parsed) ? null : parsed;
          }
        }

        fetchedNotifications.push(notification);
      });
      // Filter out notifications with invalid dates
      fetchedNotifications = fetchedNotifications.filter(n => n.messageDateNumeric);
      fetchedNotifications.sort((a, b) => b.messageDateNumeric - a.messageDateNumeric);
      setNotifications(fetchedNotifications);
      const read = fetchedNotifications.filter(n => n.readStatus === 'read');
      const unread = fetchedNotifications.filter(n => n.readStatus === 'unread');
      setReadNotifications(read);
      setUnreadNotifications(unread);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  
  const markNotificationAsRead = async (notificationId) => {
    // ...existing code for marking read...
    // just check old code for this.
  };

  const handleCreateStore = () => {
    navigate('/create-store'); // Navigate to the create-store page
  };

  const handleGoToScout = () => {
    navigate('/scout'); // Navigate to the scout page
  };
  

  const purchaseNotifications = useMemo(() => 
    notifications.filter(n => n.notificationType === 'purchaseConfirmation'), 
    [notifications]
  ); 

  const saleNotifications = useMemo(() => 
    notifications.filter(n => n.notificationType === 'sale'), 
    [notifications]
  ); 

  const deliveryNotifications = useMemo(() => 
    notifications.filter(n => n.notificationType === 'delivery'), 
    [notifications]
  );

  // Fetch product details for delivery notifications
  useEffect(() => {
    // For each delivery notification, fetch products using productID or productIDs
    const fetchProductsForNotifications = async () => {
      let updates = {};
      for (const notification of deliveryNotifications) {
        let productIDs = [];
        if (notification.productIDs && notification.productIDs.length > 0) {
          productIDs = notification.productIDs;
        } else if (notification.productID) {
          productIDs = [notification.productID];
        }

        if (productIDs.length > 0) {
          // Fetch products
          const productPromises = productIDs.map(pid => getDoc(doc(db, "products", pid)));
          const productDocs = await Promise.all(productPromises);
          const productsData = productDocs.map(docSnap => docSnap.exists() ? docSnap.data() : null).filter(Boolean);
          updates[notification.id] = productsData;
        } else {
          updates[notification.id] = [];
        }
      }
      setDeliveryNotificationProducts(updates);
    };

    if (deliveryNotifications.length > 0) {
      fetchProductsForNotifications();
    }
  }, [deliveryNotifications]);

  const togglePopup = () => { 
    if (!showInfo1) { 
      setShowNotifications(prev => !prev); 
      setPopupClosed(!popupClosed); 
    } 
  };

  const convertToReadableDate = (numericTimestamp) => {
    if (!numericTimestamp) return '';
    const date = new Date(numericTimestamp);
    if (isNaN(date.getTime())) return 'Invalid Date';
    const formattedDate = `${date.getDate()} ${date.toLocaleString('en-GB', { month: 'long' })} ${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    return formattedDate;
  };

  const handleNavigation = () => { 
    window.location.href = '/favourites'; 
  };

  const toggleDetails = (notificationId) => {
    setExpandedNotifications(prev => ({
      ...prev,
      [notificationId]: !prev[notificationId]
    }));
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  // Determine background color based on explainer step
  const homeBodyBackgroundColor = () => {
    if (!isExplainerOpen) return '#EBB89C';
    if (explainerStep === 1) return '#585123';
    if (explainerStep === 2) return '#9a3a22'; // New color for second card
    return '#EBB89C';
  };

  const homeWhereToStartVariants = {
    hidden: { opacity: 0, y: 50 },   // Start invisible and below the final position
    visible: { opacity: 1, y: 0 },   // Fully visible and in the final position
    exit: { opacity: 0, y: 50 }      // Fade out and slide down
  };

  const homeWhereToStartPaddingBottom = props.navBarState ? '65px' : '50px'
  
  return ( 
    <div 
      className='homeBody' 
      style={{ backgroundColor: homeBodyBackgroundColor(), transition: 'background-color 0.5s ease' }}
    > 
      <div className='homeHeader'> 
        <div className="iconContainer"> 
          <img src={logoIcon} alt="bootstrapt logo" id='logo'/> 
          <div className="homeSpace"/> 
          <div className='loggedInHomeIcons'> 
            <img 
              src={heartIcon} 
              onClick={handleNavigation} 
              alt="favourites page" 
              id='favourites' 
            /> 
            <img 
              onClick={togglePopup} 
              src={notificationIcon} 
              alt="notifications" 
              id='homeNotifications' 
            /> 
            <img 
              onClick={() => { 
                if (showNotifications) setShowNotifications(false); 
                setShowInfo1(!showInfo1); 
              }} 
              src={accountIcon} 
              alt="login" 
              id='login' 
            /> 
          </div> 
        </div> 
      </div> 

      {/* {!showInfo1 &&
        <motion.div 
          className="homeInitialPopup"
          initial={{ y: "-30px", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="createStoreContainer">
            <div className="createStoreSectionHeader">
              open store
            </div>
            <div className="createStoreSectionBtn" onClick={handleCreateStore}>
              create store
            </div>
            <div className="createStoreSectionBtn" onClick={() => {navigate('/learnmore')}}>
              learn more
            </div>
          </div>
          <div className="buyProductsContainer">
            <div className="buyProductsHeader">
              buy local
            </div>
            <div className="buyProductsBtn" onClick={handleGoToScout}>
              see products
            </div>
            <div className="buyProductsBtn" onClick={() => {navigate('/learnmore')}}>
              learn more
            </div>
          </div>
        </motion.div>
      } */}

      {/* Conditionally render Create Store Div if not a seller */}
      <AnimatePresence>
      {currentUser && !isSeller && (
        <motion.div 
          className="homeWhereToStartContainer"
          variants={homeWhereToStartVariants}
          style={{ paddingBottom: homeWhereToStartPaddingBottom }}
          initial="hidden"
          animate="visible"
          layout
          exit="exit"
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          <div 
            className="homeCreateStoreDiv" 
            onClick={handleCreateStore}
          >
            <p className="homeCreateStorePopupText">start your entrepreneurial journey</p>
            <button className="homeStoreBtn">
              create store
            </button>
          </div>
        </motion.div>
      )}
      </AnimatePresence>

      {/* Conditionally Render Explainer when Logged In */}
      {currentUser && (
        <div className="homeExplainerContainer">
          <Explainer 
            isExplainerOpen={isExplainerOpen}
            explainerStep={explainerStep}
            direction={direction}
            setIsExplainerOpen={setIsExplainerOpen}
            setExplainerStep={setExplainerStep}
            setDirection={setDirection}
            currentUser={currentUser}
          />
        </div>
      )}

      {/* Delivery Notifications Section */}
      {currentUser && deliveryNotifications.length > 0 && (
        <DeliveryNotifications
          deliveryNotifications={deliveryNotifications}
          deliveryNotificationProducts={deliveryNotificationProducts}
          convertToReadableDate={convertToReadableDate}
          toggleDetails={toggleDetails}
          expandedNotifications={expandedNotifications}
          handleProductClick={handleProductClick}
          userID={props.userID}
          setAlertMessage={props.setAlertMessage}
          setAlertType={props.setAlertType}
        />
      )}

      {/* Sales and Purchase History */}
      {currentUser && isSeller && (
        <div className="saleNotifications">
          <div className="saleNotificationsHeader">Sale History</div>
          {saleNotifications.length > 0 ? (
            saleNotifications.map(notification => (
              <div 
                key={notification.id} 
                className="notificationItem" 
                style={{backgroundColor: '#CBC07C'}}
              >
                <span>{notification.message}</span>
                <div className="notificationTime">{convertToReadableDate(notification.messageDateNumeric)}</div>
              </div>
            ))
          ) : (
            <div className="noSalesMessage">No sales made yet</div>
          )}
        </div>
      )}

      {currentUser && purchaseNotifications.length > 0 && (
        <div className="purchaseNotifications">
          <div className="purchaseNotificationsHeader">Purchase History</div>
          {purchaseNotifications.map(notification => (
            <div 
              key={notification.id} 
              className="notificationItem" 
              style={{backgroundColor: '#CBC07C'}}
            >
              <span>{notification.message}</span>
              <div className="notificationTime">{convertToReadableDate(notification.messageDateNumeric)}</div>
            </div>
          ))}
        </div>
      )}

      {currentUser && showNotifications && !showInfo1 &&
        <NotificationPopup 
          userID={props.userID} 
          markNotificationAsRead={markNotificationAsRead} 
          notifications={notifications} 
          unreadNotifications={unreadNotifications} 
          readNotifications={readNotifications} 
        />
      }

      {/* Modified Login Popup to conditionally include the Explainer */}
      <div ref={loginPopupRef} className="loginPopup">
        {/* Render Explainer at the top of Login when not logged in */}
        {showInfo1 && !currentUser && (
          <div className="homeExplainerContainer">
            <Explainer 
              isExplainerOpen={isExplainerOpen}
              explainerStep={explainerStep}
              direction={direction}
              setIsExplainerOpen={(open) => {
                setIsExplainerInsideLoginOpen(open); // Update new state
                setIsExplainerOpen(open);
              }}
              setExplainerStep={setExplainerStep}
              setDirection={setDirection}
              currentUser={currentUser}
            />
          </div>
        )}

        {/* Render the Login component only if Explainer is not open inside Login */}
        {!(isExplainerInsideLoginOpen) && (
          <Login 
            show={showInfo1} 
            onClickOutside={currentUser ? () => setShowInfo1(false) : undefined} 
          />
        )}
        {/* Explains the benefits of the platform */}
      </div>

      {/* Product Card Modal */}
      <AnimatePresence>
        {selectedProduct && (
          <motion.div
            className="productCardModal"
            initial={{opacity:0}}
            animate={{opacity:1}}
            exit={{opacity:0}}
            style={{
              position:'fixed', top:0, left:0, width:'100vw', height:'100vh', 
              backgroundColor:'rgba(0,0,0,0.5)', display:'flex', justifyContent:'center', alignItems:'center',
              zIndex:1000
            }}
            onClick={() => setSelectedProduct(null)}
          >
            <motion.div
              initial={{scale:0.8}}
              animate={{scale:1}}
              exit={{scale:0.8}}
              style={{backgroundColor:'#fff', padding:'20px', borderRadius:'10px', width:'300px'}}
              onClick={e => e.stopPropagation()}
            >
              <h2>{selectedProduct.productName}</h2>
              <img 
                src={selectedProduct.imageThumbnails?.[0] || blankImage} 
                alt={selectedProduct.productName} 
                style={{width:'100%', height:'auto', borderRadius:'5px'}}
              />
              <p>Price: R{parseFloat(selectedProduct.listed_price || 0).toFixed(2)}</p>
              <p>{selectedProduct.description || 'No description available.'}</p>
              <button onClick={() => setSelectedProduct(null)} style={{marginTop:'10px'}}>Close</button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <Outlet />
    </div> 
  ); 
}; 

export default Home;
