import React from 'react';
import { motion } from 'framer-motion';
import { addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, query, where, onSnapshot, deleteDoc, 
  limit, orderBy, startAfter, startAt, arrayUnion } 
from '@firebase/firestore';
import { db } from '../firebase';
import './SearchComponent.css';
import deleteIcon from '../icons/deleteIcon.png';
import searchIcon from '../icons/searchIcon.png';
import algoliasearch from 'algoliasearch';

import Select from 'react-select';

// Initialise Algolia client once, outside the component
const client = algoliasearch('7FYUJ9ESUV', '09735055cb98e3e8b3e653dc344bee7a');
const index = client.initIndex('products');

// Define container animation variants
const containerVariants = {
  collapsed: {
    width: "100%",
    height: "35px",
    x: 0,
    transition: { duration: 0.3 }
  },
  expanded: {
    width: "calc(100% + 50px)",
    height: "95px", // 35px + 50px extra height
    x: -50,
    transition: { duration: 0.3 }
  }
};

const SearchComponent = (props) => {
  // Helper function that sorts products based on the selected option.
  const sortProducts = (products, sortOrder) => {
    switch (sortOrder) {
      case "cheapest":
        return products.sort((a, b) => a.listed_price - b.listed_price);
      case "most expensive":
        return products.sort((a, b) => b.listed_price - a.listed_price);
      case "newest":
        return products.sort((a, b) => new Date(b.date_added) - new Date(a.date_added));
      case "oldest":
        return products.sort((a, b) => new Date(a.date_added) - new Date(b.date_added));
      case "product likes":
        return products.sort((a, b) => b.likes - a.likes);
      default:
        return products;
    }
  };

  const search = async () => {
    const trimmedTerm = props.inputTerm.trim();

    if (trimmedTerm === '') {
      // If there's no search term, do nothing (or reset states as needed)
      props.setSearchTerm('');
      props.setProductData([]);
      props.setNoProducts(false);
      props.setNoStores(false);
      props.setProductNotFound(false);
      props.setIsLoading(false);
      return;
    }

    props.setSearchTerm(trimmedTerm);
    props.setIsLoading(true);
    props.setActiveCategory("All");
    props.setNoProducts(false);
    props.setNoStores(false);

    try {
      const searchOptions = {
        hitsPerPage: 20,
        queryType: 'prefixAll',
        filters: 'status:published AND productSold:false',
      };

      // Perform the Algolia search
      const { hits } = await index.search(trimmedTerm, searchOptions);
      console.log(hits)
      // Sort the returned hits using the selected sort option.
      const sortedHits = sortProducts([...hits], props.sortedOption);
      console.log(props.sortedOption)
      console.log("sortedHits: ", sortedHits)
      props.setProductData(sortedHits);

      if (hits.length < 20) {
        props.setHasMore(false);
      } else {
        props.setHasMore(true);
      }

      if (hits.length === 0) {
        props.setProductNotFound(true);
        console.log("Product not found");
      } else {
        props.setProductNotFound(false);
      }

      if (props.userID) {
        try {
          const userDocRef = doc(db, "users", props.userID);
          await updateDoc(userDocRef, {
            searchHistory: arrayUnion(trimmedTerm),
          });
          console.log(`Search term "${trimmedTerm}" added to searchHistory for user ${props.userID}`);
        } catch (error) {
          console.error("Error updating searchHistory:", error);
        }
      }
    } catch (error) {
      console.error("Error during search:", error);
    } finally {
      // Only once sorting is done do we stop loading
      props.setIsLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    props.setHasSearchText(value !== '');
    props.setInputTerm(value);
  };

  let searchComponentClassName;
  if (props.parentComponent === 'market') {
    searchComponentClassName = 'marketSearchDiv';
  } else if (props.parentComponent === 'scout') {
    searchComponentClassName = 'scoutSearchDiv';
  }

  return (
    <div 
      style={{ 
        width: (props.parentComponent === 'scout') ? 'calc(100% - 50px)' : '100%'
      }}
    >
      <motion.div 
        className={searchComponentClassName}
        variants={containerVariants}
        initial="collapsed"
        animate={props.hasSearchText ? "expanded" : "collapsed"}
      >
        <div 
          className="marketSearch"
          style={{ alignItems: props.hasSearchText ? '' : 'center' }}
        >
          <div 
            className="marketSearchTopSection" 
            style={{ 
              position: props.hasSearchText ? 'relative' : '',
              top: props.hasSearchText ? '5px' : ''
            }}
          >
            <input 
              type="text" 
              placeholder="search" 
              className="marketSearchInput"
              value={props.inputTerm} 
              onChange={handleSearchChange} 
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  search();
                }
              }}
            />
            <img 
              className={`marketSearchIcon${props.hasSearchText ? 'Active' : ''}`} 
              src={searchIcon} 
              alt="Search Icon"
              // Only trigger search if there is non-empty input.
              onClick={() => {
                if (props.inputTerm.trim() !== "") {
                  search();
                }
              }}
              style={{ cursor: 'pointer' }}
            />
            {props.hasSearchText && 
              <img 
                src={deleteIcon} 
                alt="Clear Text" 
                className="marketClearTextIcon" 
                onClick={props.clearSearch}
                style={{ cursor: 'pointer' }}
              />
            }
          </div>
          {props.hasSearchText && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.3 }}
            >
              <Select
                onMenuOpen={() => props.setIsDropdownOpen(true)}
                onMenuClose={() => props.setIsDropdownOpen(false)}
                className="marketSortSelect"
                options={props.sortingOptions}
                onChange={(option) => { props.setSortedOption(option.value); }}
                placeholder="sort"
                isSearchable={false}
                styles={{
                  container: (base) => ({
                    ...base,
                    position: 'absolute',
                    left: '10px',
                    top: '45px',
                    bottom: '25px',
                    width: '27%',
                    zIndex: 10,
                  }),
                  placeholder: (base) => ({
                    ...base,
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }),
                  control: (base) => ({
                    ...base,
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    backgroundColor: 'rgba(208, 215, 215, 0.7)',
                    borderRadius: '10px',
                    fontFamily: 'Poppins',
                    fontSize: '13px',
                    boxShadow: '-1px -1px 2px rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.25) 0px 30px 25px -5px, rgba(0, 0, 0, 0.25) 0px 10px 10px -5px',
                    backdropFilter: 'blur(12px)',
                    WebkitBackdropFilter: 'blur(12px)',
                    border: 'none',
                    cursor: 'pointer',
                    zIndex: 10,
                  }),
                  singleValue: (base) => ({
                    ...base,
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }),
                  menu: (base) => ({
                    ...base,
                    position: 'absolute',
                    backgroundColor: 'rgb(241, 240, 240)',
                    borderRadius: '0px 0px 12px 12px',
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    zIndex: 5,
                    paddingTop: '34px',
                    border: 'none',
                    top: '0px',
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    visibility: 'visible',
                    color: '#000',
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected ? '#FB890E' : (state.isFocused ? '#FFC55C' : base.backgroundColor),
                    color: state.isSelected || state.isFocused ? 'rgba(0, 0, 0, 0.8)' : base.color,
                    width: '96%',
                    margin: '2px auto',
                    fontWeight: 'bold',
                    boxSizing: 'border-box',
                    overflow: 'hidden',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    textAlign: 'left',
                  }),
                  indicatorSeparator: () => ({ display: 'none' }),
                }}
              />
            </motion.div>
          )}
        </div>      
      </motion.div>
    </div>
  );
};

export default SearchComponent;
