import React, { useState } from 'react';

function TestingGround({ setAlertMessage, setAlertType }) {
  const [emailRecipient, setEmailRecipient] = useState('');

  const sendTestEmail = async () => {
    if (!emailRecipient.trim()) {
      setAlertMessage('Please enter a recipient email.');
      setAlertType('error');
      return;
    }

    try {
      const response = await fetch("https://sendemail-a6vcfarowq-uc.a.run.app", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          recipient: emailRecipient,
          recipientName: "Recipient", // Optionally make this dynamic
          subject: "Test Email from Admin",
          htmlbody: "<p>This is a test email sent from the Testing Ground tab.</p>"
        })
      });
      const data = await response.json();
      if (data.success) {
        setAlertMessage("Test email sent successfully.");
        setAlertType("success");
      } else {
        setAlertMessage("Failed to send email: " + data.error);
        setAlertType("error");
      }
    } catch (error) {
      console.error("Error sending test email:", error.message);
      setAlertMessage("Error sending email: " + error.message);
      setAlertType("error");
    }
  };

  return (
    <div className="testing-ground">
      <h2>Testing Ground</h2>
      <div className="send-email-section">
        <input 
          type="email" 
          placeholder="Enter recipient email" 
          value={emailRecipient} 
          onChange={(e) => setEmailRecipient(e.target.value)} 
        />
        <button className="send-email-button" onClick={sendTestEmail}>
          Send Test Email
        </button>
      </div>
    </div>
  );
}

export default TestingGround;
