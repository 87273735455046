// src/components/VendorFormStep4.jsx

import React, { useContext, useState } from 'react'; // Removed useState as it's no longer needed
import VendorFormReview from './VendorFormReview';
import { VendorFormContext } from '../context/VendorFormContext.jsx';
import { useNavigate } from 'react-router-dom';
import { auth, db, storage } from '../firebase'; // Ensure Firebase is initialized
import { doc, setDoc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

import './SubmitNavigationPopup.css'
import editIcon from '../icons/editIcon.png'
import plusIcon from '../icons/plusIcon.png'

const VendorFormStep4 = ({
  prev,
  storeID,
  setStoreID,
  userID,
  setUserID,
  setAlertMessage,
  setAlertType
}) => { // Correct destructuring
  const { formData, updateFormData } = useContext(VendorFormContext); // Destructure updateFormData
  const [showSubmitPopup, setShowSubmitPopup] = useState(false)
  // const [sellerDeliveryPreferance, setSellerDeliveryPreferance] = useState(formData.sellerDeliveryPreferance || ''); // Commented out

  const navigate = useNavigate();

  const handleConsentChoice = (consent) => {
    updateFormData({ consentToShowcase: consent });
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    prev();
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();

    const user = auth.currentUser; // Use currentUser instead of onAuthStateChanged
    if (user) {
      console.log("Authenticated User:", user);

      // Extract firstName and surname from displayName
      const displayName = user.displayName || '';
      const nameParts = displayName.trim().split(' ');
      
      const firstName = nameParts[0] || '';
      const surname = nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';

      console.log(`Extracted First Name: ${firstName}`);
      console.log(`Extracted Surname: ${surname}`);

      const dbRef = doc(db, 'users', user.uid);

      // Generate a unique store ID if not provided
      const finalStoreID = storeID || uuidv4();

      // Define bundlingRuleData
      const bundlingRuleData = {
        "Aramex Standard (35 x 45cm)": 1,
        "Locker-to-Locker Pudo Extra Small (60 x 17 x 8cm)": 1,
        "Locker-to-Locker Pudo Small (60 x 41 x 8cm)": 1,
        "Locker-to-Locker Pudo Medium (60 x 41 x 19cm)": 1,
        "Locker-to-Locker Pudo Large (60 x 41 x 41cm)": 1,
        "Locker-to-Locker Pudo Extra Large (60 x 41 x 69cm)": 1,
        "Door-to-Locker Pudo Extra Small (60 x 17 x 8cm)": 1,
        "Door-to-Locker Pudo Small (60 x 41 x 8cm)": 1,
        "Door-to-Locker Pudo Medium (60 x 41 x 19cm)": 1,
        "Door-to-Locker Pudo Large (60 x 41 x 41cm)": 1,
        "Door-to-Locker Pudo Extra Large (60 x 41 x 69cm)": 1,
        "Locker-to-Door Pudo Extra Small (60 x 17 x 8cm)": 1,
        "Locker-to-Door Pudo Small (60 x 41 x 8cm)": 1,
        "Locker-to-Door Pudo Medium (60 x 41 x 19cm)": 1,
        "Locker-to-Door Pudo Large (60 x 41 x 41cm)": 1,
        "Locker-to-Door Pudo Extra Large (60 x 41 x 69cm)": 1,
        "Paxi Standard (45 x 37cm)": 1,
        "Paxi Large (64 x 51cm)": 1
      };

      // Prepare the data to be saved in the 'users' and 'stores' collections
      const storeInfo = {
        email: user.email,
        user: user.displayName,
        displayName: user.displayName,
        firstName: firstName, 
        surname: surname,     
        userID: user.uid,
        phone: formData.phone,
        address: formData.address,
        store: formData.store,
        store_description: formData.store_description,
        fileName: formData.fileName,
        store_logo: formData.store_logo,
        storeID: finalStoreID, // Use the finalStoreID
        industry: formData.industry,
        likes: 0,
        bundlingRules: bundlingRuleData,
        offeringType: formData.offeringType,
        operationalDuration: formData.operationalDuration,
        businessLocationType: formData.businessLocationType,
        goalsExpectations: formData.goalsExpectations,
        goalsExpectationsOther: formData.goalsExpectationsOther || '',
        describeBrand: formData.describeBrand,
        keyWords: formData.keyWords,
        style: formData.style || '',
        socialMediaLinks: formData.socialMediaLinks || '',
        // sellerDeliveryPreferance: sellerDeliveryPreferance, // Removed
        deliveryMethod: 'courier', // Added deliveryMethod with default value
        createdBy: user.uid,
        createdAt: new Date(),
        consentToShowcase: formData.consentToShowcase || '', // <-- New field
        // createdAt for users, but date_added for products
        // Add any other store-specific fields here
      };

      try {
        // Use setDoc with merge to create or update the user document
        await setDoc(dbRef, storeInfo, { merge: true });
      } catch (error) {
        console.error("Error updating user document:", error);
        setAlertMessage(`Failed to update user information: ${error.message}. Please try again.`);
        setAlertType('error');
        return;
      }

      console.log("User info added to database:", storeInfo);

      // Create store document within the 'stores' collection
      const storeDbRef = doc(db, 'stores', finalStoreID);

      try {
        await setDoc(storeDbRef, storeInfo);
      } catch (error) {
        console.error("Error creating store document:", error);
        setAlertMessage("Failed to create store. Please try again.");
        setAlertType('error');
        return;
      }

      console.log("Store has been successfully created in the database.");

      // Delete non-resized logo if exists
      if (formData.storagePath) { // Use storagePath instead of nonResizedLogoURL
        const deleteRef = ref(storage, formData.storagePath);
        try {
          await deleteObject(deleteRef);
          console.log("Non-resized logo successfully deleted");
        } catch (error) {
          console.error("Error deleting non-resized logo:", error);
          setAlertMessage("There was an error deleting your logo");
          setAlertType('error');        
        }
      }

      setAlertMessage("You have successfully created your store");
      setAlertType('success');
      setShowSubmitPopup(true)
    }
  };

  const navigateToVendor = () => {
    navigate("/vendor", { state: { newStoreCreated: true } })
  }

  const navigateToAddProduct = () => {
    navigate("/addproduct", { state: { newStoreCreated: true } })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCreateAccount(e); // Pass the event
  };

  // Commented out the renderDeliveryPreference function as it's no longer needed
  /*
  const renderDeliveryPreference = () => {
    return (
      <div className="deliveryPreferenceContainer">
        <p className="deliveryPreferenceTitle">Delivery Preference</p>
        <div className="deliveryButtons">
          <div 
            type="button" 
            className={`deliveryButton ${sellerDeliveryPreferance === 'driverPickup' ? 'selectedPreference' : ''}`} 
            onClick={() => {
              setSellerDeliveryPreferance('driverPickup');
              updateFormData({ sellerDeliveryPreferance: 'driverPickup' });
            }}
          >
            Driver Pickup
          </div>
          <div 
            type="button" 
            className={`deliveryButton ${sellerDeliveryPreferance === 'localCollect' ? 'selectedPreference' : ''}`} 
            onClick={() => {
              setSellerDeliveryPreferance('localCollect');
              updateFormData({ sellerDeliveryPreferance: 'localCollect' });
            }}
          >
            Local Collection
          </div>
          <div 
            type="button" 
            className={`deliveryButton ${sellerDeliveryPreferance === 'both' ? 'selectedPreference' : ''}`} 
            onClick={() => {
              setSellerDeliveryPreferance('both');
              updateFormData({ sellerDeliveryPreferance: 'both' });
            }}
          >
            Both
          </div>
        </div>
      </div>
    );
  };
  */

  return (
    <form onSubmit={handleSubmit}>
      {/* {renderDeliveryPreference()} */} {/* Commented out the delivery preference UI */}

      {/* New consent div (visually similar to offeringTypeQuestion) */}
      <div className="socialMediaConsentQuestion">
        <div className="socialMediaConsentQuestionHeader">
          Do you consent to us promoting and showcasing 
          your business online using social media?
          <br/>
          <br/>
          This includes using your business' logo, store description, 
          store name, product images and product descriptions, 
          amongst other things.
        </div>
        <div className="offeringTypeBtnDiv">
          <div
            className={`offeringTypeButton ${formData.consentToShowcase === 'yes' ? 'selected' : ''}`}
            onClick={() => handleConsentChoice('yes')}
          >
            yes
          </div>
          <div
            className={`offeringTypeButton ${formData.consentToShowcase === 'no' ? 'selected' : ''}`}
            onClick={() => handleConsentChoice('no')}
          >
            no
          </div>
        </div>
      </div>

      <div className="vendorFormStep4Div">
        You’re almost done! Click back to review your information 
        and submit to create your store.
      </div>
      <div className="buttons">
        <button type="button" onClick={handlePrevClick}>back</button>
        <button type="submit">
          submit
        </button>
      </div>

      {showSubmitPopup && 
      <div className="submitPopupBlackBackground">
        <div className="submitNavPopup">
          <div className="submitNavPopupHeader">next steps</div>
          <div className="submitVendorNavigate" onClick={navigateToVendor}>
            create your website
            <img src={editIcon} alt="" className="submitNavBtnIcon" />
          </div>
          <div className="submitAddProductNavigate" onClick={navigateToAddProduct}>
            upload your first product
            <img src={plusIcon} alt="" className="submitNavBtnIcon" />
          </div>
        </div>
      </div>
      }
    </form>
  );
};

export default VendorFormStep4;
