import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { 
  collection, query, where, orderBy, onSnapshot
} from '@firebase/firestore';
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from '../firebase';
import { motion, AnimatePresence, LayoutGroup, useAnimation } from "framer-motion";
import { createPortal } from 'react-dom';
import './WebsiteProductSection.css';

import ScoutCard from '../scout/ScoutCard';
import ImageGallery from '../scout/ImageGallery'; // <-- NEW import
import closeIcon from '../icons/deleteIcon.png';
import backArrow from '../icons/returnArrowIcon.png';

const portalRoot = document.getElementById('portal-root') || (() => {
  const div = document.createElement('div');
  div.id = 'portal-root';
  document.body.appendChild(div);
  return div;
})();

const WebsiteProductSection = (props) => {
  const [storeProducts, setStoreProducts] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userID, setUserID] = useState(null);

  // States for expanded ScoutCard
  const [isShown, setIsShown] = useState(false);
  const [expandedProductData, setExpandedProductData] = useState(null);
  const [imageGrowthAnimation, setImageGrowthAnimation] = useState(false);
  const [colorArray, setColorArray] = useState([]);

  // States for "already in cart" message
  const productInCartAnimation = useAnimation();
  const [showCartMessage, setShowCartMessage] = useState(false);

  // States for text-div expansions (long-press, etc.)
  const [showTextDivMessage, setShowTextDivMessage] = useState(false);
  const [infoDivExpanded, setInfoDivExpanded] = useState(false);

  const wideScreenBreakpoint = 670;
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= wideScreenBreakpoint);

  // -----------------------
  // NEW: Image Gallery States & Methods
  // -----------------------
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryThumbnails, setGalleryThumbnails] = useState([]);

  const openGallery = (images, thumbnails) => {
    setGalleryImages(images || []);
    setGalleryThumbnails(thumbnails || []);
    setIsGalleryOpen(true);
  };

  const closeGallery = () => {
    setIsGalleryOpen(false);
  };
  // -----------------------

  // Fetch products from the store
  useEffect(() => {
    if (props.storeID) {
      const productDoc = query(
        collection(db, "products"),
        where("storeID", "==", props.storeID),
        where("status", "==", "published"),
        where("productSold", "==", false),
        orderBy("date_added", "desc")
      );

      const unsubscribe = onSnapshot(productDoc, (querySnapshot) => {
        let productArr = [];
        querySnapshot.forEach((doc) => {
          productArr.push(doc.data());
        });
        setStoreProducts(productArr);
      });

      return () => unsubscribe();
    }
  }, [props.storeID]);

  // Handle authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserID(user.uid);
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= wideScreenBreakpoint);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Handle product click to show ScoutCard
  const handleProductClick = (product) => {
    setExpandedProductData(product);
    setIsShown(true);
    setImageGrowthAnimation(true);
  };

  // Close the expanded ScoutCard
  const closeExpandedProductCard = () => {
    setIsShown(false);
    setExpandedProductData(null);
  };

  // Handle dominant colour (if needed)
  const handleDominantColor = (dominantColor, index) => {
    setColorArray((prevColors) => {
      const newColors = [...prevColors];
      newColors[index] = dominantColor;
      return newColors;
    });
  };

  // Handle "already in cart" message
  const alreadyInCart = useCallback(
    (alreadyInCartMessage) => {
      setShowCartMessage(alreadyInCartMessage);
      if (alreadyInCartMessage === true) {
        setShowCartMessage(true);

        // Start the animation
        productInCartAnimation.start({
          y: 0,
          opacity: 1,
          transition: { duration: 0.5 },
        });

        // Reset the animation after 4 seconds
        setTimeout(() => {
          productInCartAnimation.start({
            y: -30,
            opacity: 0,
            transition: { duration: 0.5 },
          });
        }, 4000);
      } else {
        setShowCartMessage(false);
      }
    },
    [productInCartAnimation]
  );

  useEffect(() => {
    if (showTextDivMessage) {
      const timer = setTimeout(() => {
        setShowTextDivMessage(false);
      }, 3000); 
      return () => clearTimeout(timer);
    }
  }, [showTextDivMessage, setShowTextDivMessage]);

  // Framer Motion variants
  const variants = {
    hidden: { y: '100vh', opacity: 0 },
    visible: { y: 0, opacity: 1 },
    exit: { y: '100vh', opacity: 0 },
  };

  return (
    <>
      {/* AnimatePresence for the product section */}
      <AnimatePresence>
        {/* Only render the product list if the ScoutCard is NOT shown */}
        {!isShown && (
          <motion.div
            key="websiteProductSection"
            className="websiteProductSection"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={variants}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            style={{ y: props.animation }}
          >
            <img 
              src={closeIcon} 
              alt="Close" 
              className="websiteProductSectionClose" 
              onClick={props.close}
            />
            <div className="websiteProductSectionTop">
              <div className="websiteProductSectionHeader">store</div>
              {storeProducts.length > 0 ? (
                storeProducts.map((product, index) => (
                  <div className="websiteProduct" key={index}>
                    <div className="websiteProductPrice">R{product.listed_price}</div>
                    <img
                      className="websitePopupStoreProduct"
                      src={product.images[0]}
                      alt={product.title}
                      onClick={() => handleProductClick(product)}
                    />
                  </div>
                ))
              ) : (
                <div className="emptyWebsiteProductSection">
                  The store currently does not have any products
                </div>
              )}
            </div>

            {/* "Already in cart" message */}
            {showCartMessage && (
              <motion.div
                className="alreadyInCartDiv"
                initial={{ y: -30, opacity: 0 }}
                animate={productInCartAnimation}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="alreadyInCartDivText">
                  The product is already in the cart. To review it, go to the cart page.
                </div>
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      {/* Portal for the ScoutCard (only shows when isShown === true) */}
      {isShown && expandedProductData && createPortal(
        <div className="websiteCardPopupDiv">
          <AnimatePresence>
            {showTextDivMessage && (
              <motion.div
                className="websiteTextDivMessage"
                initial={{ y: -30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -30, opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                {infoDivExpanded 
                  ? 'Longpress the description box to collapse it.' 
                  : 'Longpress the description box to expand it.'
                }
              </motion.div>
            )}
          </AnimatePresence>
          <div className="websiteCardPopupContent">
            <div className="websiteCardClose" onClick={closeExpandedProductCard}>
              <div className="websiteCardBlurredBackground">
                <img src={backArrow} alt="Close" id="websiteBackIcon" />
              </div>
            </div>
            <ScoutCard
              showLogin={props.showLogin}
              imageGrowthAnimation={imageGrowthAnimation}
              isLoggedIn={isLoggedIn}
              productID={expandedProductData.productID}
              data={expandedProductData}
              image={expandedProductData.images}
              imageThumbnail={expandedProductData.imageThumbnails}
              onDominantColor={handleDominantColor}
              listed_price={expandedProductData.listed_price}
              title={expandedProductData.productName}
              store={expandedProductData.store}
              storeID={expandedProductData.storeID}
              description={expandedProductData.description}
              category={expandedProductData.category}
              navBarState={props.navBarState}
              userID={userID}
              bundling={expandedProductData.bundling}
              alreadyInCart={alreadyInCart}
              isWideScreen={isWideScreen}
              openEthicalChecksPopup={props.openEthicalChecksPopup}
              showTextDivMessage={showTextDivMessage}
              setInfoDivExpanded={setInfoDivExpanded}
              setShowTextDivMessage={setShowTextDivMessage}
              openGallery={openGallery}
              country={props.country}
            />
          </div>
        </div>,
        portalRoot
      )}

      {/* NEW: AnimatePresence for the ImageGallery */}
      <AnimatePresence>
        {isGalleryOpen && (
          <ImageGallery
            image={galleryImages}
            imageThumbnail={galleryThumbnails}
            closeGallery={closeGallery}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default WebsiteProductSection;
