import React, { useEffect, useState } from 'react';
import { motion, LayoutGroup } from "framer-motion";
import ImageGrain from './ImageGrain';
import './ScoutCardImage.css';
import heartIcon from '../icons/heartIcon.png';
import redHeartIcon from '../icons/redHeartIcon.png';
import bundlingIcon from '../icons/bundlingIcon.png';
import shareIcon from '../icons/shareIcon.png';

const ScoutCardImage = ({
  page,
  imageSrc,
  isWideScreen,
  expandInfo,
  animate,
  hasWhiteColor,
  onHeartIconClick,
  liked,
  isBundling,
  preventDrag,
  productID,
  imageVariants,
  wideImageVariants,
  imageDivVariants,
  wideImageDivVariants,
  buyBtnText,
  onBuyButtonClick,
  buyDisabled,
  hideBuyButton,
  price,
  openGallery,
  images,
  imageThumbnail,
  setAlertMessage,
  setAlertType,
  country
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  // Handle image load to update state
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  // Function to generate gradients (unchanged)
  const generateGradients = () => {
    const gradients = [];
    const edges = ['top', 'bottom', 'left', 'right'];
    const numGradientsPerEdge = 9;

    edges.forEach(edge => {
      for (let i = 0; i < numGradientsPerEdge; i++) {
        let positionX, positionY;

        switch(edge) {
          case 'top':
            positionX = `${Math.random() * 100}%`;
            positionY = `0%`;
            break;
          case 'bottom':
            positionX = `${Math.random() * 100}%`;
            positionY = `100%`;
            break;
          case 'left':
            positionX = `0%`;
            positionY = `${Math.random() * 100}%`;
            break;
          case 'right':
            positionX = `100%`;
            positionY = `${Math.random() * 100}%`;
            break;
          default:
            positionX = `0%`;
            positionY = `0%`;
        }

        const r = 255;
        const g = Math.floor(200 + Math.random() * 50);
        const b = Math.floor(150 + Math.random() * 50);
        const a = 0.2 + Math.random() * 0.2;

        gradients.push(`radial-gradient(circle at ${positionX} ${positionY}, rgba(${r}, ${g}, ${b}, ${a}) 0%, transparent 3px)`);
      }
    });

    return gradients.join(', ');
  };

  const [gradients, setGradients] = useState('');
  useEffect(() => {
    setGradients(generateGradients());
  }, [imageSrc]);

  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  // Variants for the image container remain unchanged
  const containerVariants = {
    expanded: {
      width: '33.33%',
      height: '100%',
      borderRadius: '20px',
      overflow: 'hidden'
    },
    collapsed: {
      width: '100%',
      height: '100%',
    },
  };

  return (
    <motion.div 
      className={`imageDiv${animate ? (expandInfo ? 'true' : 'false') : ''}`} 
      alt="scout card image"
      variants={isWideScreen ? wideImageDivVariants : imageDivVariants}
      initial={false}
      animate={expandInfo ? "expanded" : "collapsed"}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      onClick={() => {
        console.log("Scout image clicked");
        openGallery(images, imageThumbnail);
      }}
    >
      <motion.div 
        className='imageContainer1'
        style={{ position: 'relative' }}
        variants={containerVariants}
        initial={hasMounted ? false : expandInfo ? "expanded" : "collapsed"}
        animate={expandInfo ? "expanded" : "collapsed"} 
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <LayoutGroup id={`productImage-${productID}`}>
          {/* Skeleton loader shown only until the image loads */}
          {!imageLoaded && (
            <motion.div
              className="skeleton"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: '#eee',
                zIndex: 2
              }}
              animate={{ opacity: [0.8, 0.5, 0.8] }}
              transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
            />
          )}

          <motion.img 
            className={`scoutCardImage${animate ? (expandInfo ? 'true' : 'false') : ''}`} 
            src={imageSrc} 
            onDoubleClick={onHeartIconClick}
            layoutId={`productImage-${productID}`}
            onClick={openGallery}
            alt="Product"
            style={{ backgroundColor: 'white', width: '100%', height: '100%' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: imageLoaded ? 1 : 0 }}
            transition={{ duration: 1, ease: "easeInOut" }}
            onLoad={handleImageLoad}
          />
        </LayoutGroup>
        
        <div className="vintage-overlay"/>
        <div className="vintage-edge-overlay" style={{ background: gradients }}/>
        
        <ImageGrain />
      </motion.div>

      {imageLoaded && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          <div className="price">R{price}</div>
          {(!hideBuyButton && page !== 'dashboard') && (
            <div 
              className={`buyButton${animate ? (expandInfo ? 'true' : 'false') : ''}`} 
              disabled={buyDisabled}
              onClick={(e) => {
                e.stopPropagation(); // Prevent event bubbling
                onBuyButtonClick();
              }}
            >
              {buyBtnText}
            </div>
          )}
        </motion.div>
      )}

      {expandInfo && (
        <div className="iconsDivtrue">
          <div 
            className="scoutIcons" 
            alt="heart icon" 
            onClick={(e) => onHeartIconClick(e)} 
            onDragStart={preventDrag}
          >
            {liked ? 
              <img id="redHeartIcontrue" src={redHeartIcon} alt="Liked" onDragStart={preventDrag}/> 
              : 
              <img id="heartIcontrue" src={heartIcon} alt="Like" onDragStart={preventDrag}/>
            }
          </div>
          {isBundling && 
            <img 
              src={bundlingIcon} 
              alt="bundling icon" 
              className="scoutIcons" 
              id="bundlingIcontrue" 
              onDragStart={preventDrag}
            />
          }
          <img 
            src={shareIcon} 
            alt="share icon" 
            className="scoutIcons" 
            id="shareIcontrue" 
            onDragStart={preventDrag}
          />
        </div>
      )}
    </motion.div>
  );
};

export default ScoutCardImage;
