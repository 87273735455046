import React from 'react';

const BuyerDetails = ({
  firstName,
  surname,
  email,
  cellphone,
  buyerAddress,
  isSuggestionsVisible,
  addressSuggestions,
  handleChange,
  handleSuggestionClick,
  handleUseCurrentAddress,
  isFetchingLocation
}) => {
  return (
    <div className='buyerDetailsContainer'>
      <input 
        id="buyerInfoFirstNameInput" 
        type="text" 
        placeholder=" First Name" 
        value={firstName}
        onChange={handleChange} 
        name="firstName"
        required 
      />
      <input 
        id="buyerInfoSurnameInput" 
        type="text" 
        placeholder=" Surname" 
        value={surname}
        onChange={handleChange} 
        name="surname"
        required 
      />
      <input 
        id="buyerInfoEmailInput" 
        type="email" 
        placeholder=" Email" 
        value={email}
        onChange={handleChange} 
        name="email"
        required 
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
        title="Please enter a valid email address."
      />
      <input 
        id="buyerInfoCellphoneInput" 
        type="tel" 
        placeholder=" Cellphone" 
        value={cellphone}
        onChange={handleChange} 
        name="cellphone"
        required 
        pattern="^\+?[0-9]{10,15}$"
        title="Please enter a valid cellphone number."
      />
      <div className="buyerInfoAddressDiv">
        <div className="addressAutocompleteDiv">
          <input
            id="buyerInfoAddressInput" 
            type="text"
            placeholder=" Physical Address"
            value={buyerAddress}
            onChange={handleChange}
            name="address"
            required
            autoComplete="off"
          />
          {isSuggestionsVisible && addressSuggestions.length > 0 && (
            <ul className="buyerInfoPopupAddressSuggestionsList">
              {addressSuggestions.map((suggestion) => (
                <li
                  key={suggestion.id}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="suggestionItem"
                > 
                  {suggestion.place_name.replace(/,\s*South Africa$/, '')}
                </li>
              ))}
            </ul>
          )}
        </div>
        <button 
          type="button" 
          className="locateAddressBtn" 
          id="useCurrentAddressBtn" 
          onClick={handleUseCurrentAddress}
          disabled={isFetchingLocation}
          aria-label="Use Current Address"
        >
          {isFetchingLocation ? "Fetching Address..." : "Use Current Address"}
        </button>
      </div>
    </div>
  );
};

export default BuyerDetails;
