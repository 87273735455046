import React, { useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Alert.css"; // Ensure this CSS file styles the alert appropriately

const Alert = ({ component, message, type, onClose }) => {
  const alertRef = useRef(null);

  useEffect(() => {
    // Auto-close the alert after 5 seconds (5000ms)
    const timer = setTimeout(() => {
      onClose();
    }, 5000); // Adjusted to 5 seconds based on 5000ms

    return () => clearTimeout(timer);
  }, [onClose]);

  useEffect(() => {
    // Function to handle clicks outside the alert
    const handleClickOutside = (event) => {
      if (alertRef.current && !alertRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Attach the event listener only if component is not 'login'
    if (component !== "login") {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on unmount or when component changes
    return () => {
      if (component !== "login") {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, [component, onClose]);

  return (
    <AnimatePresence>
      {message && (
        <motion.div
          ref={alertRef}
          className={`alert ${type === "error" ? "alert-error" : "alert-success"}`}
          initial={{ y: component === "login" ? "-100%" : "100%", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: component === "login" ? "-100%" : "100%", opacity: 0 }}
          transition={{ duration: 0.5 }}
          style={{ 
            top: (component === 'login') ? '100%' : '0',
            width: (component !== 'login') ? 'calc(100% - 20px)' : '100%',
            margin: (component !== 'login') ? '10px' : '10px 0'
          }}
          onClick={(e) => {
            // Prevent closing when clicking inside the alert
            e.stopPropagation();
          }}
        >
          {message}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Alert;
