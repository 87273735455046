import React from 'react';
import infoIcon from '../icons/infoIcon.png';

const BuyerShippingDetails = ({
  cartData,
  uniqueCourierMethods,
  sortedCourierMethods,
  paxiStoreCode,
  pudoLockerAddress,
  showPaxiInput,
  setShowPaxiInput,
  showPudoInput,
  setShowPudoInput,
  toggleMethodInfo,
  showMethodInfo,
  shippingInformation,
  handleChange,
  buyerAddress
}) => {
  return (
    <div className='buyerShippingDetailsContainer'>
      <div className="buyerInfoCourierMethods" style={{ position: 'relative' }}>
        {sortedCourierMethods.length > 0 ? (
          <div className="buyerInfoCourierMethodsInset">
            {sortedCourierMethods.map((item, index) => {
              const { courierMethod, subgroups } = item;
              const relevantPudoSubgroups = ['Locker-to-Locker', 'Door-to-Locker'];
              const pudoRelevantSubgroups = subgroups.filter(sub => relevantPudoSubgroups.includes(sub));

              // Compute estimated shipping time for this courier method
              const shippingMethodInfo = shippingInformation.find(method => method.name === courierMethod);
              let estimatedTime = 'N/A';
              if (shippingMethodInfo) {
                if (shippingMethodInfo.options && shippingMethodInfo.options.length > 0) {
                  estimatedTime = shippingMethodInfo.options[0].estimatedTime;
                } else if (shippingMethodInfo.subgroups && shippingMethodInfo.subgroups.length > 0) {
                  const firstSubgroup = shippingMethodInfo.subgroups[0];
                  if (firstSubgroup.options && firstSubgroup.options.length > 0) {
                    estimatedTime = firstSubgroup.options[0].estimatedTime;
                  }
                }
              }

              return (
                <div key={index} className="shippingMethodContainer" style={{ position: 'relative' }}>
                  <strong>
                    {courierMethod}
                    {courierMethod === 'Pudo' && pudoRelevantSubgroups.length > 0 && (
                      <span> ({pudoRelevantSubgroups.join(', ')})</span>
                    )}
                  </strong>
                  {courierMethod !== 'Aramex' && (
                    <img 
                      className="buyerInfoIcon infoIconTopRight"
                      onClick={() => toggleMethodInfo(courierMethod)} 
                      aria-label="More Info"
                      src={infoIcon}
                      alt="Info"
                    />
                  )}
                  {courierMethod === 'Aramex' && (
                    <div className="aramexMessage" style={{ marginTop: '10px' }}>
                      Your parcel will be shipped to:
                      <br/> 
                      <strong>{buyerAddress}</strong>
                    </div>
                  )}
                  {courierMethod !== 'Pudo' && subgroups.length > 0 && (
                    <div>
                      {subgroups.map((sub, subIndex) => (
                        <div key={subIndex}>{sub}</div>
                      ))}
                    </div>
                  )}
                  {courierMethod !== 'Aramex' && showMethodInfo[courierMethod] && (
                    <div className="buyerExtraInfoPopup">
                      {courierMethod === 'Paxi' && (
                        <div className="infoPopupSection">
                          Please visit the Paxi site by clicking the "Find Store" button and then input your address. Copy the code (e.g. P8215) and paste it.
                        </div>
                      )}
                      {courierMethod === 'Pudo' && (
                        <div className="infoPopupSection">
                          Please visit the Pudo site by clicking the "Find Locker" button and then input your address into the "Search for Lockers" field. Copy the address and paste it.
                        </div>
                      )}
                      {courierMethod !== 'Paxi' && courierMethod !== 'Pudo' && (
                        <div className="infoPopupSection">
                          No additional information is available for {courierMethod}.
                        </div>
                      )}
                    </div>
                  )}
                  {courierMethod === 'Paxi' && (
                    <div className="conditionalDiv paxiDiv">
                      <button 
                        type="button" 
                        className="findStoreButton" 
                        onClick={() => { 
                          setShowPaxiInput(true)
                          window.open('https://www.paxi.co.za/paxi-points', '_blank'); 
                        }}
                        aria-label="Find or Change Paxi Store"
                      >
                        {paxiStoreCode ? "Change Store" : "Find Store"}
                      </button>
                      {paxiStoreCode && !showPaxiInput ? (
                        <span className="courierLocationExistingValue">Store Code: {paxiStoreCode}</span>
                      ) : (
                        showPaxiInput && (
                          <>
                            <label htmlFor="paxiStoreCode">Paxi Store Code:</label>
                            <input 
                              id="paxiStoreCodeInput" 
                              type="text" 
                              placeholder="P1234" 
                              value={paxiStoreCode}
                              onChange={handleChange} 
                              name="paxiStoreCode"
                              required 
                              pattern="^P\d{4}$"
                              title="Store code must start with 'P' followed by 4 digits."
                            />
                          </>
                        )
                      )}
                    </div>
                  )}
                  {courierMethod === 'Pudo' && pudoRelevantSubgroups.length > 0 && (
                    <div className="conditionalDiv pudoDiv">
                      <label className="pudoLockerAddressHeader" htmlFor="pudoLockerAddress">Pudo Locker Address:</label>
                      <div className="pudoInputContainer">
                        {pudoLockerAddress && !showPudoInput ? (
                          <span className="courierLocationExistingValue">Locker Address: {pudoLockerAddress}</span>
                        ) : (
                          showPudoInput && (
                            <>
                              <input 
                                id="pudoLockerAddressInput" 
                                type="text" 
                                placeholder="Enter Locker Location" 
                                value={pudoLockerAddress}
                                onChange={handleChange} 
                                name="pudoLockerAddress"
                                required 
                              />
                            </>
                          )
                        )}
                        <button 
                          type="button" 
                          className="findLockerButton" 
                          onClick={() => { 
                            setShowPudoInput(true)
                            window.open('https://www.pudo.co.za/where-to-find-us.php', '_blank'); 
                          }}
                          aria-label="Find or Change Pudo Locker"
                        >
                          {pudoLockerAddress ? "Change Locker" : "Find Locker"}
                        </button>
                      </div>
                    </div>
                  )}
                  {/* Shipping Estimated Time is now included within each shippingMethodContainer */}
                  <div className="shippingEstimatedTime">
                    <strong>{courierMethod} Estimated Time:</strong> {estimatedTime}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <p>No shipping methods selected.</p>
        )}
      </div>
    </div>
  );
};

export default BuyerShippingDetails;
