import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import './Admin.css'; 
import { 
  collection, 
  getCountFromServer, 
  addDoc, 
  Timestamp, 
  getDocs, 
  orderBy, 
  query, 
  where 
} from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import path as necessary
import { getFunctions, httpsCallable } from "firebase/functions";
import Alert from '../login/Alert'; // Assuming you have an Alert component

import AdminOrderHistory from './AdminOrderHistory';
import Payments from './Payments';
import Transactions from './Transactions';
import StoreInfo from './StoreInfo';
import TestingGround from './TestingGround';

import backArrow from '../icons/backArrow.png';

function Admin() {
  const [productCount, setProductCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [storeCount, setStoreCount] = useState(0);
  const [growthRecords, setGrowthRecords] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [adminEmail, setAdminEmail] = useState('');
  const [activeTab, setActiveTab] = useState('dashboard');
  const functions = getFunctions();

  // Fetch counts when the component mounts
  useEffect(() => {
    const fetchCounts = async () => {
      try {
        // Fetch count for published products
        const productsCol = collection(db, 'products');
        const publishedProductsQuery = query(productsCol, where('status', '==', 'published'));
        const productsSnapshot = await getCountFromServer(publishedProductsQuery);
        setProductCount(productsSnapshot.data().count);

        // Fetch count for users
        const usersCol = collection(db, 'users');
        const usersSnapshot = await getCountFromServer(usersCol);
        setUserCount(usersSnapshot.data().count);

        // Fetch count for stores
        const storesCol = collection(db, 'stores');
        const storesSnapshot = await getCountFromServer(storesCol);
        setStoreCount(storesSnapshot.data().count);
      } catch (error) {
        console.error('Error fetching counts:', error);
        setAlertMessage('Failed to fetch counts. Please try again later.');
        setAlertType('error');
      }
    };

    fetchCounts();
  }, []);

  useEffect(() => {
    document.title = 'Admin | taizte™';
  }, []);

  // Fetch growth records
  useEffect(() => {
    const fetchGrowthRecords = async () => {
      try {
        const growthCol = collection(db, 'growthRecords');
        const q = query(growthCol, orderBy('date', 'desc'));
        const growthSnapshot = await getDocs(q);
        const records = growthSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setGrowthRecords(records);
      } catch (error) {
        console.error('Error fetching growth records:', error);
        setAlertMessage('Failed to fetch growth records.');
        setAlertType('error');
      }
    };

    fetchGrowthRecords();
  }, []);

  // Function to record figures
  const recordFigures = async () => {
    try {
      const growthRecord = {
        date: Timestamp.now(),
        productCount,
        userCount,
        storeCount,
      };

      await addDoc(collection(db, 'growthRecords'), growthRecord);

      setAlertMessage('Growth figures recorded successfully.');
      setAlertType('success');

      // Optionally, update growthRecords state to include the new record
      setGrowthRecords(prevRecords => [
        { id: 'temp-id', ...growthRecord },
        ...prevRecords,
      ]);
    } catch (error) {
      console.error('Error recording figures:', error);
      setAlertMessage('Failed to record figures. Please try again.');
      setAlertType('error');
    }
  };

  // Function to assign admin role using Cloud Function
  const assignAdminRole = async () => {
    if (!adminEmail.trim()) {
      setAlertMessage('Please enter a valid email address.');
      setAlertType('error');
      return;
    }

    try {
      const addAdminRole = httpsCallable(functions, 'addAdminRole');
      const result = await addAdminRole({ email: adminEmail });
      if (result.data.error) {
        setAlertMessage(result.data.error);
        setAlertType('error');
      } else {
        setAlertMessage(result.data.message);
        setAlertType('success');
        setAdminEmail(''); // Clear input after success
      }
    } catch (error) {
      console.error('Error assigning admin role:', error);
      setAlertMessage('Failed to assign admin role. Please try again.');
      setAlertType('error');
    }
  };

  return (
    <div className="admin-container">
      <div className="adminHeaderSection">
        <Link to="/">
          <div className="adminBack">
            <img src={backArrow} className="adminBackIcon" alt="Back" />
          </div>
        </Link>
        <div className="admin-container-header">admin</div>
      </div>
      
      {/* Alert Component */}
      <Alert component={'admin'} message={alertMessage} type={alertType} onClose={() => setAlertMessage('')} />

      {/* Navigation Tabs */}
      <div className="admin-tabs">
        <button
          className={`tab-button ${activeTab === 'dashboard' ? 'adminActive' : ''}`}
          onClick={() => setActiveTab('dashboard')}
        >
          Dashboard
        </button>
        <button
          className={`tab-button ${activeTab === 'orderHistory' ? 'adminActive' : ''}`}
          onClick={() => setActiveTab('orderHistory')}
        >
          Order History
        </button>
        <button
          className={`tab-button ${activeTab === 'payments' ? 'adminActive' : ''}`}
          onClick={() => setActiveTab('payments')}
        >
          Payments
        </button>
        <button
          className={`tab-button ${activeTab === 'transactions' ? 'adminActive' : ''}`}
          onClick={() => setActiveTab('transactions')}
        >
          Transactions
        </button>
        <button
          className={`tab-button ${activeTab === 'stores' ? 'adminActive' : ''}`}
          onClick={() => setActiveTab('stores')}
        >
          Stores
        </button>
        <button
          className={`tab-button ${activeTab === 'testing' ? 'adminActive' : ''}`}
          onClick={() => setActiveTab('testing')}
        >
          Testing Ground
        </button>
      </div>

      <div className="admin-tab-content">
        {activeTab === 'dashboard' && (
          <>
            <div className="adminDashboardContainer1">
              <div className="overview-section">
                <div className="overview-card">
                  <h2>Users</h2>
                  <p className="overview-number">{userCount}</p>
                </div>
                <div className="overview-card">
                  <h2>Products</h2>
                  <p className="overview-number">{productCount}</p>
                </div>
                <div className="overview-card">
                  <h2>Stores</h2>
                  <p className="overview-number">{storeCount}</p>
                </div>
              </div>

              <button className="record-button" onClick={recordFigures}>
                Record Figures
              </button>
            </div>

            {/* Growth Records Section */}
            <div className="growth-records-section">
              <h2>Growth Records</h2>
              {growthRecords.length === 0 ? (
                <p>No growth records available.</p>
              ) : (
                <table className="growth-records-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Products</th>
                      <th>Users</th>
                      <th>Stores</th>
                    </tr>
                  </thead>
                  <tbody>
                    {growthRecords.map(record => (
                      <tr key={record.id}>
                        <td>{record.date.toDate().toLocaleString()}</td>
                        <td>{record.productCount}</td>
                        <td>{record.userCount}</td>
                        <td>{record.storeCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>

            {/* Assign Admin Role Section */}
            <div className="assign-admin-section">
              <h2>Assign Admin Role</h2>
              <input 
                type="email" 
                placeholder="User Email" 
                value={adminEmail} 
                onChange={(e) => setAdminEmail(e.target.value)} 
              />
              <button className="assign-admin-button" onClick={assignAdminRole}>
                Make Admin
              </button>
            </div>
          </>
        )}
        {activeTab === 'orderHistory' && <AdminOrderHistory />}
        {activeTab === 'payments' && <Payments />}
        {activeTab === 'transactions' && <Transactions />}
        {activeTab === 'stores' && <StoreInfo />}
        {activeTab === 'testing' && (
          <TestingGround setAlertMessage={setAlertMessage} setAlertType={setAlertType} />
        )}
      </div>
    </div>
  );
}

export default Admin;
