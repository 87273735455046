import { BrowserRouter, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db, storage } from './firebase';
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { doc, setDoc, getDoc } from '@firebase/firestore';

import PortraitLock from "./PortraitLock"; 
import Layout from './Layout';
import Home from "./home/Home";
import Favourites from "./home/Favourites";
import Scout from "./scout/Scout";
import Market from "./market/Market";
import Vendor from "./vendor/Vendor";
import Website from "./user website/Website";
import AddProduct from "./vendor/add product/AddProduct";
import Wallet from "./vendor/wallet/Wallet";
import Dashboard from "./vendor/dashboard/Dashboard";
import Cart from "./cart/Cart";
import CreateStore from "./create store/CreateStore";
import StorelessUser from "./create store/StorelessUser";
import useFavicon from "./user website/useFavicon";
import Alert from './login/Alert';
import FullScreenPopup from "./FullScreenPopup";
import ProtectedRoute from './ProtectedRoute';
import Admin from "./admin/Admin";
import './Layout.css'; 

import { OfflineErrorContext } from './OfflineErrorContext';

// Import the new Learn More component
import LearnMore from "./home/LearnMore";

function ResetProductSavedMessage({ setProductSavedMessage, productSavedMessageAnimation }) {
  const location = useLocation();
  const lastPath = useRef();

  useEffect(() => {
    if (lastPath.current !== '/addproduct') {
      productSavedMessageAnimation.start({
        y: 50,
        opacity: 0,
        transition: { duration: 0.5 },
      }).then(() => {
        setTimeout(() => {
          setProductSavedMessage(false);
        }, 500);
      });
    }
    lastPath.current = location.pathname;
  }, [location, productSavedMessageAnimation, setProductSavedMessage]);

  return null;
}

function App() {
  const [isMobile, setIsMobile] = useState(null);
  const [user, setUser] = useState();
  const [websiteName, setWebsiteName] = useState();
  const [navBarState, setNavBarState] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const offlineMessageAnimation = useAnimation();
  const productSavedMessageAnimation = useAnimation();
  const [productSavedMessage, setProductSavedMessage] = useState(false);
  const [transitionStarted, setTransitionStarted] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success"); 
  const [userRole, setUserRole] = useState(null);

  const { offlineErrorMessage } = useContext(OfflineErrorContext)

  const [country, setCountry] = useState('');
  const navigate = useNavigate();
  
  // Fetch country data on mount
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setCountry(data.country_name);
        console.log(`User country: ${data.country_name}`);
      } catch (error) {
        console.error('Error fetching country:', error);
      }
    };

    fetchCountry();
  }, []);

  const setFavicon = useFavicon('/favicon.ico');

  useEffect(() => {
    // Set default favicon when the app loads
    setFavicon('/favicon.ico');
    console.log('Default favicon set in App.jsx');
  }, [setFavicon]);

  // Prevent default context menu globally
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    window.addEventListener('contextmenu', handleContextMenu);

    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    function updateOnlineStatus() {
      setIsOnline(navigator.onLine);
      if (navigator.onLine) {
        offlineMessageAnimation.start({
          y: -30,
          opacity: 0,
          transition: { duration: 0.5 },
        });
      } else {
        offlineMessageAnimation.start({
          y: 0,
          opacity: 1,
          transition: { duration: 0.5 },
        });
      }
    }
  
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
  
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, [offlineMessageAnimation]);
  
  // Manage authentication state globally
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);

        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const role = userDoc.data().role;
          setUserRole(role);
        } else {
          setUserRole("user");
        }
      } else {
        setUser(null);
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const navBarStateFunction = useCallback(async (state) => {
    setNavBarState(state);
  }, []);

  const setProductSavedMessageFunction = useCallback((state) => {
    if (state) {
      setProductSavedMessage(true);
      setTimeout(() => {
        setProductSavedMessage(false);
      }, 3500);
    }
  }, []);

  const renderProductSavedMessage = () => {
    return (
      <AnimatePresence>
        {productSavedMessage && (
          <motion.div className="productSavedMessagePopupContainer" 
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }}
            exit={{ y: 100, opacity: 0, transition: { duration: 0.5 } }}
          >
            <div className="productSavedMessageDiv">
              Your product draft has been saved to your dashboard for future editing.
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  return (
    <>
      <PortraitLock />
      <ResetProductSavedMessage setProductSavedMessage={setProductSavedMessage} productSavedMessageAnimation={productSavedMessageAnimation} />
      <motion.div className="offlineMessagePopupContainer" 
        initial={{ y: -50, opacity: 0 }}
        animate={offlineMessageAnimation}
        exit={{ y: -50, opacity: 0 }}
      >
        <motion.div className="offlinePopupMessageDiv">
          {!isOnline && 'You are currently offline. Please check your network connection.'}
          {offlineErrorMessage && <div className="offlineErrorMessage">{offlineErrorMessage}</div>}
        </motion.div>
      </motion.div>

      <Alert component={'app'} message={alertMessage} type={alertType} onClose={() => setAlertMessage("")} />
      {!isMobile &&
        <FullScreenPopup navBarOpen={navBarState} />
      }
      <Routes>
        <Route path="/" element={
          <Layout 
            userID={user ? user.uid : null} 
            navBarStateFunction={navBarStateFunction}
            navBarState={navBarState} 
          />
        }>
          {/* Make Scout the initial (index) route */}
          <Route index element={
            <Scout 
              userID={user ? user.uid : null} 
              navBarState={navBarState} 
              setNavBarState={setNavBarState}
              country={country} 
              setTransitionStarted={setTransitionStarted} 
              transitionStarted={transitionStarted} 
              setAlertMessage={setAlertMessage} 
              setAlertType={setAlertType}
            />
          }/>
          {/* Home is now available at /home */}
          <Route path="home" element={
            <Home 
              userID={user ? user.uid : null} 
              navBarState={navBarState} 
              country={country} 
              setAlertMessage={setAlertMessage} 
              setAlertType={setAlertType}
            />
          }/>
          {/* Still keep /scout route */}
          <Route path="scout/:product?" element={
            <Scout 
              userID={user ? user.uid : null} 
              navBarState={navBarState} 
              setNavBarState={setNavBarState}
              country={country} 
              setTransitionStarted={setTransitionStarted} 
              transitionStarted={transitionStarted} 
              setAlertMessage={setAlertMessage} 
              setAlertType={setAlertType}
            />
          }/>          
          <Route path="market" element={
            <Market 
              userID={user ? user.uid : null} 
              setTransitionStarted={setTransitionStarted} 
              setAlertMessage={setAlertMessage} 
              setAlertType={setAlertType}
            />
          }/>
          <Route path="vendor" element={
            <Vendor 
              userID={user ? user.uid : null} 
              navBarState={navBarState} 
              country={country} 
              setAlertMessage={setAlertMessage} 
              setAlertType={setAlertType}
            />
          }/>
          <Route path="cart" element={
            <Cart 
              userID={user ? user.uid : null} 
              navBarState={navBarState} 
              setTransitionStarted={setTransitionStarted} 
              transitionStarted={transitionStarted} 
              setAlertMessage={setAlertMessage} 
              setAlertType={setAlertType} 
              country={country}
            />
          }/>
          <Route path="favourites" element={
            <Favourites 
              userID={user ? user.uid : null} 
              setAlertMessage={setAlertMessage} 
              setAlertType={setAlertType}
            />
          }/>
          {/* New Learn More page */}
          <Route path="learnmore" element={<LearnMore />} />
          {/* This dynamic route is placed after the static routes to avoid matching conflicts */}
          <Route path="/:websiteName" element={
            <Website 
              userID={user ? user.uid : null} 
              navBarState={navBarState}  
              setTransitionStarted={setTransitionStarted} 
              transitionStarted={transitionStarted} 
              setAppFavicon={setFavicon} 
              setAlertMessage={setAlertMessage} 
              setAlertType={setAlertType}
              country={country}
            />
          }/>
          <Route path="create-store" element={
            <StorelessUser 
              userID={user ? user.uid : null} 
              navBarState={navBarState} 
              setAlertMessage={setAlertMessage} 
              setAlertType={setAlertType} 
              country={country} 
              userRole={userRole}
            />
          }/>
          <Route path="addproduct" element={
            <AddProduct 
              user={user} 
              setProductSavedMessageFunction={setProductSavedMessageFunction} 
              navBarState={navBarState} 
              setAlertMessage={setAlertMessage} 
              setAlertType={setAlertType} 
              country={country}
            />
          }/>
          <Route path="wallet" element={
            <Wallet 
              userID={user ? user.uid : null} 
              navBarState={navBarState} 
              setAlertMessage={setAlertMessage} 
              setAlertType={setAlertType} 
              country={country}
            />
          }/>
          <Route path="dashboard" element={
            <Dashboard 
              userID={user ? user.uid : null} 
              navBarState={navBarState} 
              setTransitionStarted={setTransitionStarted} 
              transitionStarted={transitionStarted} 
              setAlertMessage={setAlertMessage} 
              setAlertType={setAlertType} 
              country={country}
            />
          }/>
          <Route 
            path="admin" 
            element={
              <ProtectedRoute user={user} userRole={userRole} requiredRole="admin">
                <Admin />
              </ProtectedRoute>
            } 
          />
        </Route> 
      </Routes>
      {renderProductSavedMessage()}
    </>
  );
}

export default App;
