import { useState, useEffect, useRef } from "react";
import {
  getDoc,
  doc,
  setDoc,
  updateDoc,
  runTransaction,
  arrayUnion,
  getDocs,
  collection,
  serverTimestamp
} from "@firebase/firestore";
import { db } from "../firebase";
import { motion, AnimatePresence } from "framer-motion";

import loadIcon from '../icons/scoutIcon.png';
import './PaymentPopup.css';

const PaymentPopup = ({
  setShowPaymentPopup,
  onClosePaymentPopup, // Callback to trigger refresh in Cart
  userID,
  totalAmount,
  setAlertMessage,
  setAlertType,
  stores,
  buyerInfo,
  cartShippingTotal, // Passed from Cart.js
  productTotal,      // Passed from Cart.js
  cartTotal,          // Passed from Cart.js
  cartData
}) => {
  const paymentPopupRef = useRef();

  const [deliveryFee, setDeliveryFee] = useState(0);
  const [buyerAddress, setBuyerAddress] = useState("");
  const [yocoSDK, setYocoSDK] = useState(null);
  const [publicKey, setPublicKey] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // NEW: local flag to prevent re-triggering the payment popup
  const [paymentAttempted, setPaymentAttempted] = useState(false);

  // Fetch additional data like deliveryFee and buyerAddress from Firestore
  useEffect(() => {
    const fetchAdditionalData = async () => {
      if (userID) {
        const cartRef = doc(db, "cart", userID);
        const cartSnap = await getDoc(cartRef);
        if (cartSnap.exists()) {
          const cartData = cartSnap.data();
          setDeliveryFee(cartData.deliveryFee || 0);
          setBuyerAddress(cartData.buyerAddress || "");
        }
      }
    };

    fetchAdditionalData();
  }, [userID]);

  // Fetch YOCO public key for payment processing
  useEffect(() => {
    const fetchPublicKey = async () => {
      try {
        const response = await fetch(`https://getyocopublickey-a6vcfarowq-uc.a.run.app/get-yoco-public-key`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch YOCO public key");
        }

        const data = await response.json();
        setPublicKey(data.publicKey);
      } catch (error) {
        console.error("Error fetching YOCO public key:", error.message);
        setAlertMessage("Failed to initialise payment gateway.");
        setAlertType("error");
        setShowPaymentPopup(false);
        onClosePaymentPopup?.();
      } finally {
        setIsLoading(false);
      }
    };

    fetchPublicKey();
  }, [setAlertMessage, setAlertType, setShowPaymentPopup, onClosePaymentPopup]);

  // Initialize YOCO SDK after obtaining the public key
  useEffect(() => {
    if (publicKey) {
      if (window.YocoSDK) {
        const yoco = new window.YocoSDK({ publicKey });
        setYocoSDK(yoco);
      } else {
        const script = document.createElement("script");
        script.src = "https://js.yoco.com/sdk/v1/yoco-sdk-web.js";
        script.async = true;
        script.onload = () => {
          const yoco = new window.YocoSDK({ publicKey });
          setYocoSDK(yoco);
        };
        script.onerror = () => {
          setAlertMessage("Failed to load Yoco SDK");
          setAlertType("error");
          setShowPaymentPopup(false);
          onClosePaymentPopup?.();
          console.log('CLOSED')
        };
        document.body.appendChild(script);
      }
    }
  }, [publicKey, setAlertMessage, setAlertType, setShowPaymentPopup, onClosePaymentPopup]);

  // Trigger payment popup once YOCO SDK is ready
  // NEW: Only call handlePayment if paymentAttempted is false.
  useEffect(() => {
    if (yocoSDK && !isLoading && totalAmount > 0 && !paymentAttempted) {
      setPaymentAttempted(true);
      handlePayment(totalAmount);
    }
  }, [yocoSDK, isLoading, totalAmount, paymentAttempted])

  /**
   * Handles the payment process using YOCO SDK.
   * @param {number} totalAmount - The total amount to be charged.
   */
  const handlePayment = (totalAmount) => {
    if (!yocoSDK) {
      setAlertMessage("Payment gateway is not ready. Please try again later.");
      setAlertType("error");
      setShowPaymentPopup(false);
      onClosePaymentPopup?.();
      return;
    }

    if (totalAmount === 0) {
      setAlertMessage("Total amount cannot be R0. Please check your cart.");
      setAlertType("error");
      setShowPaymentPopup(false);
      onClosePaymentPopup?.();
      return;
    }

    const amountInCents = totalAmount * 100;

    yocoSDK.showPopup({
      amountInCents,
      currency: "ZAR",
      styles: {
        primaryColor: "#FF5733",
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
      },
      callback: (result) => {
        if (result.error) {
          // Popup closed due to error or user cancellation
          setAlertMessage("Error: " + result.error.message);
          setAlertType("error");
          setShowPaymentPopup(false);
          onClosePaymentPopup?.();
        } else {
          // Proceed to process the payment
          processPayment(result.id, totalAmount, stores, buyerInfo);
        }
      },
    });
  };

  // Helper function to send an email to the store
  const sendSaleEmail = async (storeEmail, storeName, saleTotal, courierMethod, productNames) => {
    try {
      const subject = `New Sale on ${storeName}`;
      const htmlbody = `<p>Dear ${storeName},</p>
                        <p>A new sale has occurred with the following details:</p>
                        <ul>
                          <li><strong>Purchase Amount:</strong> R${saleTotal.toFixed(2)}</li>
                          <li><strong>Courier Method(s):</strong> ${courierMethod}</li>
                          <li><strong>Product(s):</strong> ${productNames.join(", ")}</li>
                        </ul>
                        <p>Please go to <a href="https://taizte.com/vendor">taizte.co/vendor</a> and click the dashboard (cog) icon to manage the sale.</p>
                        <p>Best regards,</p>
                        <p>Your Taizte Team</p>`;
      const response = await fetch("https://sendemail-a6vcfarowq-uc.a.run.app", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          recipient: storeEmail,
          recipientName: storeName,
          subject,
          htmlbody,
        }),
      });
      const data = await response.json();
      if (data.success) {
        console.log(`Sale email sent successfully to ${storeEmail}`);
      } else {
        console.error("Failed to send sale email:", data.error);
      }
    } catch (error) {
      console.error("Error sending sale email:", error.message);
    }
  };

  /**
   * Processes the payment by updating Firestore with order details.
   * @param {string} token - The payment token returned by YOCO.
   * @param {number} amount - The total amount paid.
   * @param {Array} stores - Array of store objects containing products.
   * @param {Object} buyerInfo - Information about the buyer.
   */
  const processPayment = async (token, amount, stores, buyerInfo) => {
    try {
      // Send payment details to your backend for processing
      const response = await fetch(
        `https://processyocopayment-a6vcfarowq-uc.a.run.app/process-payment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token,
            amount: amount * 100,
            currency: "ZAR",
            description: "Purchase Products",
            metadata: {
              userID,
              stores,
              deliveryFee,
              buyerAddress,
              buyerInfo,
            },
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        setAlertMessage("Payment successful!");
        setAlertType("success");

        // Process each store involved in the purchase
        const transactionPromises = stores.map(async (store) => {
          const storeID = store.storeID;
          const products = store.products.map(product => ({
            ...product,
            productSold: true // Mark product as sold
          }));

          const orderHistoryRef = doc(db, "orderHistory", storeID);
          const adminOrderHistoryRef = doc(db, "adminOrderHistory", storeID);

          // Calculate totals and fees
          const saleTotal = products.reduce((acc, product) => acc + (parseFloat(product.seller_price) * (product.quantity || 1)), 0);
          const platformFees = products.reduce((acc, product) => acc + ((parseFloat(product.listed_price) - parseFloat(product.seller_price)) * (product.quantity || 1)), 0);
          const sellerFees = saleTotal; // Sum of seller_price for each product
          const paymentGatewayFee = saleTotal * 0.0295; // 2.95% payment gateway fee
          const platformOrderProfit = platformFees - paymentGatewayFee; // Profit after subtracting payment gateway fee

          await runTransaction(db, async (transaction) => {
            const orderHistoryDoc = await transaction.get(orderHistoryRef);
            const adminOrderHistoryDoc = await transaction.get(adminOrderHistoryRef);

            // Update orderHistory for the store
            if (!orderHistoryDoc.exists()) {
              transaction.set(orderHistoryRef, {
                orderHistory: arrayUnion({
                  saleDate: new Date(),
                  products: products,
                  buyerInfo: buyerInfo,
                  cartShippingTotal: cartShippingTotal, // Total shipping cost
                  cartTotal: cartTotal,                 // Total cart cost
                  productTotal: productTotal            // Total product cost
                }),
                storeID,
                lifetimeSales: saleTotal,
                productsSoldNum: products.reduce((acc, product) => acc + (product.quantity || 1), 0),
              });
            } else {
              const currentData = orderHistoryDoc.data();
              transaction.update(orderHistoryRef, {
                orderHistory: arrayUnion({
                  saleDate: new Date(),
                  products,
                  buyerInfo,
                  cartShippingTotal: cartShippingTotal,
                  cartTotal: cartTotal,
                  productTotal: productTotal
                }),
                lifetimeSales: currentData.lifetimeSales + saleTotal,
                productsSoldNum: currentData.productsSoldNum + products.reduce((acc, product) => acc + (product.quantity || 1), 0),
              });
            }

            // Update adminOrderHistory for the store
            if (!adminOrderHistoryDoc.exists()) {
              transaction.set(adminOrderHistoryRef, {
                adminOrderHistory: arrayUnion({
                  saleDate: new Date(),
                  products,
                  buyerInfo,
                  cartShippingTotal: cartShippingTotal,
                  cartTotal: cartTotal,
                  productTotal: productTotal,
                  platformFees: platformFees,
                  sellerFees: sellerFees,
                  paymentGatewayFee: paymentGatewayFee,
                  platformOrderProfit: platformOrderProfit
                }),
                storeID,
                lifetimePlatformFees: platformFees,
                lifetimePaymentGatewayFees: paymentGatewayFee,
                paymentGatewayValue: paymentGatewayFee,
                netProfit: platformOrderProfit,
                lifetimeSales: saleTotal,
              });
            } else {
              const currentAdminData = adminOrderHistoryDoc.data();
              transaction.update(adminOrderHistoryRef, {
                adminOrderHistory: arrayUnion({
                  saleDate: new Date(),
                  products,
                  buyerInfo,
                  cartShippingTotal: cartShippingTotal,
                  cartTotal: cartTotal,
                  productTotal: productTotal,
                  platformFees: platformFees,
                  sellerFees: sellerFees,
                  paymentGatewayFee: paymentGatewayFee,
                  platformOrderProfit: platformOrderProfit
                }),
                storeID,
                lifetimePlatformFees: (currentAdminData.lifetimePlatformFees || 0) + platformFees,
                lifetimePaymentGatewayFees: (currentAdminData.lifetimePaymentGatewayFees || 0) + paymentGatewayFee,
                paymentGatewayValue: (currentAdminData.paymentGatewayValue || 0) + paymentGatewayFee,
                netProfit: (currentAdminData.netProfit || 0) + platformOrderProfit,
                lifetimeSales: (currentAdminData.lifetimeSales || 0) + saleTotal,
              });
            }
          });

          // ---UPDATED SECTION BELOW---
          // Update each product's status in Firestore AND decrement quantity by 1 (not going below 0).
          const updateProductPromises = products.map(async (product) => {
            const productRef = doc(db, "products", product.productID);

            await runTransaction(db, async (transaction) => {
              const productDoc = await transaction.get(productRef);
              if (productDoc.exists()) {
                const productData = productDoc.data();
                // Decrement quantity, ensuring it never goes below 0
                let newQuantity = (productData.quantity || 1) - 1;
                if (newQuantity < 0) {
                  newQuantity = 0;
                }
                // Update fields in the same transaction
                transaction.update(productRef, {
                  quantity: newQuantity,
                  productSold: true,
                  productSent: false,
                  productReceived: false,
                  shippingStatus: "pending",
                  status: "sold",
                  buyerInfo: buyerInfo,
                });
              }
            });
          });
          // ---UPDATED SECTION ABOVE---

          await Promise.all(updateProductPromises);

          // Send sale email notification to the store.
          // Define courier methods based on each product's courierMethod field from cartData.
          // Here we assume that store.products is a subset of cartData.
          const courierMethods = [
            ...new Set(store.products.map(product => product.selected_shippingName || "Standard"))
          ];
          const courierMethodStr = courierMethods.join(", ");
          const productNames = store.products.map(product => product.productName);
          // It is assumed that the store object contains an 'email' field and a 'storeName' field.
          await sendSaleEmail(store.email, store.storeName, saleTotal, courierMethodStr, productNames);
        });

        await Promise.all(transactionPromises);

        // Handle notifications for buyer and store
        const purchasedProducts = stores.flatMap(store => store.products.map(product => ({
          productID: product.productID,
          productName: product.productName,
          storeID: store.storeID,
          storeName: store.storeName,
          userID: userID
        })));

        const purchasedProductIDs = purchasedProducts.map(p => p.productID);

        // Remove purchased products from favourites
        const favQuerySnapshot = await getDocs(collection(db, "favourites"));
        const removeFromFavouritesPromises = favQuerySnapshot.docs.map(async (favDoc) => {
          const likedProducts = favDoc.data().liked_products;
          if (!likedProducts) return;

          const updatedLikedProducts = likedProducts.filter(likedProduct => !purchasedProductIDs.includes(likedProduct.productID));
          if (updatedLikedProducts.length !== likedProducts.length) {
            await updateDoc(doc(db, "favourites", favDoc.id), {
              liked_products: updatedLikedProducts
            });
          }
        });

        await Promise.all(removeFromFavouritesPromises);

        // Clear cart after purchase
        const cartRef = doc(db, "cart", userID);
        const cartSnap = await getDoc(cartRef);
        if (cartSnap.exists()) {
          const cartData = cartSnap.data();
          const updatedCartProducts = cartData.cart.filter(cartProduct => !purchasedProductIDs.includes(cartProduct.productID));

          if (updatedCartProducts.length !== cartData.cart.length) {
            await updateDoc(cartRef, {
              cart: updatedCartProducts,
              cartTotal: 0
            });
          }
        }

        // Create notifications for buyer and store
        const createNotificationPromises = purchasedProducts.map(async (product) => {
          // Notification for Buyer
          const buyerNotificationRef = doc(collection(db, 'notifications'));
          await setDoc(buyerNotificationRef, {
            notificationType: 'purchaseConfirmation',
            message: `You have successfully purchased '${product.productName}' from ${product.storeName}.`,
            messageDate: new Date().toLocaleString(),
            messageDateNumeric: serverTimestamp(),
            messageID: buyerNotificationRef.id,
            readStatus: 'unread',
            storeName: product.storeName,
            productName: product.productName,
            storeID: product.storeID,
            userID: userID
          });

          // Notification for Store Owner
          const storeRef = doc(db, "stores", product.storeID);
          const storeSnap = await getDoc(storeRef);
          if (storeSnap.exists()) {
            const storeData = storeSnap.data();
            const storeNotificationRef = doc(collection(db, 'notifications'));
            await setDoc(storeNotificationRef, {
              notificationType: 'sale',
              message: `The product titled '${product.productName}' has been purchased.`,
              messageDate: new Date().toLocaleString(),
              messageDateNumeric: serverTimestamp(),
              messageID: storeNotificationRef.id,
              readStatus: 'unread',
              storeName: product.storeName,
              productName: product.productName,
              storeID: product.storeID,
              userID: storeData.ownerID
            });
          }
        });

        await Promise.all(createNotificationPromises);

        // **Add sellerFees and platformFees to each payment document**
        const paymentPromises = stores.map(async (store) => {
          const paymentRef = doc(collection(db, "payments"));
          
          // Calculate sellerFees and platformFees for the current store
          const saleTotal = store.products.reduce((acc, product) => acc + (parseFloat(product.seller_price) * (product.quantity || 1)), 0);
          const platformFees = store.products.reduce((acc, product) => acc + ((parseFloat(product.listed_price) - parseFloat(product.seller_price)) * (product.quantity || 1)), 0);
          const sellerFees = saleTotal;

          await setDoc(paymentRef, {
            userID: userID,
            storeID: store.storeID,
            storeName: store.storeName,
            products: store.products,
            totalAmount: store.products.reduce((sum, product) => sum + (parseFloat(product.listed_price) * (product.quantity || 1)), 0),
            sellerFees: sellerFees,        
            platformFees: platformFees,    
            shippingStatus: "pending",
            productSent: false,
            productReceived: false,
            paymentDate: new Date(),
            buyerInfo: buyerInfo,
          }, { merge: true });
        });

        await Promise.all(paymentPromises);

        // Finally, clear the cart completely
        await setDoc(doc(db, "cart", userID), {
          cart: [],
          deliveryFee: 0,
          buyerAddress: "",
        });

        // Close the entire payment popup
        setShowPaymentPopup(false);
        onClosePaymentPopup?.(); // Trigger re-fetch in the parent, so the cart data is refreshed
      } else {
        setAlertMessage("Payment failed: " + data.message);
        setAlertType("error");
        setShowPaymentPopup(false);
        onClosePaymentPopup?.();
      }
    } catch (error) {
      setAlertMessage("Payment processing error: " + error.message);
      setAlertType("error");
      setShowPaymentPopup(false);
      onClosePaymentPopup?.();
    }
  };

  return (
    <div className="paymentPopupContainer" ref={paymentPopupRef}>
      <AnimatePresence>
        {isLoading && (
          <motion.div
            className="paymentPopupLoadingDiv"
            exit="exit"
            initial={{ opacity: 1, scale: 1, y: 0 }}
          >
            <img
              src={loadIcon}
              className="cartLoadingIcon"
              alt="loading icon"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PaymentPopup;
