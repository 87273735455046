import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import Alert from '../login/Alert';
import './StoreInfo.css'; // Import the StoreInfo specific CSS

function StoreInfo() {
  const [stores, setStores] = useState([]);
  const [storeRecords, setStoreRecords] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');

  // Fetch stores from Firestore
  useEffect(() => {
    const fetchStores = async () => {
      try {
        const storesCol = collection(db, 'stores');
        const storesSnapshot = await getDocs(storesCol);
        const storesList = storesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        // Sort: stores with no products come first
        storesList.sort((a, b) => {
          const aHasProducts = a.productCount > 0 ? 1 : 0;
          const bHasProducts = b.productCount > 0 ? 1 : 0;
          return aHasProducts - bHasProducts;
        });
        setStores(storesList);
      } catch (error) {
        console.error('Error fetching stores:', error);
        setAlertMessage('Failed to fetch store data.');
        setAlertType('error');
      }
    };
    fetchStores();
  }, []);

  // Compute percentage of stores with products
  const totalStores = stores.length;
  const storesWithProducts = stores.filter(store => store.productCount > 0).length;
  const percentageWithProducts = totalStores > 0 ? ((storesWithProducts / totalStores) * 100).toFixed(2) : 0;

  // Fetch store records
  useEffect(() => {
    const fetchStoreRecords = async () => {
      try {
        const recordsCol = collection(db, 'storeRecords');
        const recordsSnapshot = await getDocs(recordsCol);
        const records = recordsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setStoreRecords(records);
      } catch (error) {
        console.error('Error fetching store records:', error);
      }
    };
    fetchStoreRecords();
  }, []);

  // Function to record store info data
  const recordStoreData = async () => {
    try {
      const record = {
        date: Timestamp.now(),
        totalStores,
        storesWithProducts,
        percentageWithProducts: Number(percentageWithProducts)
      };
      await addDoc(collection(db, 'storeRecords'), record);
      setAlertMessage('Store data recorded successfully.');
      setAlertType('success');
      setStoreRecords(prev => [{ id: 'temp-id', ...record }, ...prev]);
    } catch (error) {
      console.error('Error recording store data:', error);
      setAlertMessage('Failed to record store data.');
      setAlertType('error');
    }
  };

  return (
    <div className="storeInfoContainer">
      <Alert component="store" message={alertMessage} type={alertType} onClose={() => setAlertMessage('')} />
      <div className="storeInfoTopSection">
        <h2>Stores Info</h2>
        <div className="percentageInfo">
          <h3>Percentage of stores with products: {percentageWithProducts}%</h3>
        </div>

        <button className="recordButton" onClick={recordStoreData}>
          Record Store Data
        </button>
      </div>

      <div className="storesGrid">
        {stores.map(store => (
          <div
            key={store.id}
            className="storeCard"
            style={{
              borderRadius: '8px',
              border: '1px solid #ccc',
              padding: '10px',
              margin: '10px',
              backgroundColor: store.productCount === 0 ? 'red' : 'white',
              color: store.productCount === 0 ? 'red' : 'inherit'
            }}
          >
            <p><strong>Store Name:</strong> {store.store}</p>
            <p><strong>Email:</strong> {store.email}</p>
            <p><strong>Cell:</strong> {store.phone}</p>
            <p><strong>Name:</strong> {store.firstName}</p>
            <p><strong>Surname:</strong> {store.surname}</p>
            <p><strong>Products:</strong> {store.productCount || 0}</p>
          </div>
        ))}
      </div>

      <div className="storeRecordsSection">
        <h3>Store Records</h3>
        {storeRecords.length === 0 ? (
          <p>No store records available.</p>
        ) : (
          <table className="storeRecordsTable">
            <thead>
              <tr>
                <th>Date</th>
                <th>Total Stores</th>
                <th>Stores with Products</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {storeRecords.map(record => (
                <tr key={record.id}>
                  <td>
                    {record.date.toDate
                      ? record.date.toDate().toLocaleString()
                      : new Date(record.date.seconds * 1000).toLocaleString()}
                  </td>
                  <td>{record.totalStores}</td>
                  <td>{record.storesWithProducts}</td>
                  <td>{record.percentageWithProducts}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default StoreInfo;
